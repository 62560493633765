import axios from "axios";
import { decryptData } from "../Functions/Data_protection";
import { secretKey , apiUrl} from "../constant";


export const Buildingload = async (uri , referenceid , role  ) => {

    const response = await axios.get(`${apiUrl}${uri}`, {
        params: { referenceid , role },
      });
    const decryptedData = decryptData(response.data.payload, secretKey);
    console.log(decryptedData)
    return decryptedData


    

}
