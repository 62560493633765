export const handlefilter = (event, originalRecords, setRecords) => {
    const searchTerm = event.target.value.toLowerCase();
    console.log(searchTerm);
    if (searchTerm === '') {
        setRecords(originalRecords);
    } else {
        const filteredData = originalRecords.filter(row => 
            Object.values(row).some(value =>
                value !== undefined && value !== null && value.toString().toLowerCase().includes(searchTerm)
            )
        );
        setRecords(filteredData);
    }
};
