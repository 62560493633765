import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const CircularProgressBar = ({ percentage }) => {
    const [displayPercentage, setDisplayPercentage] = useState(0);
    const [radius, setRadius] = useState(100); // Initial radius value

    useEffect(() => {
        setDisplayPercentage(percentage);

        
        const handleResize = () => {
            if(window.innerWidth >= 1024){
              setRadius(120);
            }
            else if (window.innerWidth >= 768) {
                setRadius(60); 
            } else if (window.innerWidth >= 480) {
                setRadius(120); 
            } else {
                setRadius(100);
            }
        };

        handleResize(); // Call handleResize initially
        window.addEventListener('resize', handleResize); // Listen for window resize events

        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup
        };
    }, [percentage]);

    const normalizedRadius = radius - 5; // Subtract stroke width
    const circumference = normalizedRadius * 1.5 * Math.PI; // 3/4 circle circumference
    const strokeDashoffset = circumference - (displayPercentage / 100) * circumference;

    return (
        <StyledCircularbar radius={radius}>
            <svg height={radius * 2} width={radius * 2} className="circular-progress-bar">
                <circle
                    stroke="lightgrey"
                    fill="transparent"
                    strokeWidth="5"
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                    strokeDasharray={`${circumference} ${circumference}`}
                    strokeDashoffset="25%"
                    className="circular-progress-bar__background"
                />
                <circle
                    stroke="tomato"
                    fill="transparent"
                    strokeWidth="5"
                    strokeDasharray={`${circumference} ${circumference}`}
                    style={{ strokeDashoffset }}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                    className="circular-progress-bar__progress"
                />
                <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" className="circular-progress-bar__text">
                    {displayPercentage}%
                </text>
            </svg>
        </StyledCircularbar>
    );
};

const StyledCircularbar = styled.div`
    .circular-progress-bar {
        overflow: visible;
    }

    .circular-progress-bar__background {
        transform: rotate(165deg);
        transform-origin: center;
    }

    .circular-progress-bar__progress {
        transform: rotate(135deg);
        transform-origin: center;
        transition: stroke-dashoffset 1s ease-in-out;
    }

    .circular-progress-bar__text {
        font-size: ${({ radius }) => radius * 0.25}px;
        fill: #333;
        transform: translateY(24%);
    }

    @media screen and (max-width: 768px) {
        .circular-progress-bar__text {
            font-size: ${({ radius }) => radius * 0.15}px;
        }
    }

    @media screen and (max-width: 480px) {
        .circular-progress-bar__text {
            font-size: ${({ radius }) => radius * 0.08}px;
        }
    }
`;

export default CircularProgressBar;
