import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Logo from './Components/Sidebar_components/Logo';
import NavPanel from './Components/Sidebar_components/Nav_panel';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { HamContext } from './Context/Ham';

const Sidebar = () => {
    const hamState = useContext(HamContext);
    const [sidebarexpanded, setSidebarexpanded] = useState(hamState.sidebarexpanded);

    useEffect(() => {
        setSidebarexpanded(hamState.sidebarexpanded);
    }, [hamState.sidebarexpanded]);

    const handleCloseArrowClicked = () => {
        hamState.setSidebarexpanded(!sidebarexpanded);
    };

    return (
        <StyledSidebar sidebarexpanded={sidebarexpanded}>
            <Logo />
            <NavPanel />
            <button className="closearrow" onClick={handleCloseArrowClicked}>
                {/* <FontAwesomeIcon icon={faArrowLeft} id='ham' /> */}
            </button>
        </StyledSidebar>
    );
};

const StyledSidebar = styled.div`
    position: relative; /* Ensure the positioning context for child elements */
    min-height: 100vh;
    width: 15%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fdd5d6;
    padding: 40px 2% 0 2%;
    overflow-x: hidden;

    .closearrow {
        display: none;
    }

    @media screen and (max-width: 480px) {
        width: ${({ sidebarexpanded }) => (sidebarexpanded ? '50%' : '0')};
        transform: ${({ sidebarexpanded }) => (sidebarexpanded ? 'translateX(0)' : 'translateX(-120%)')};
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out; 


        .closearrow {
            display: block;
            position: absolute;
            top: 2vh;
            left: 88%;
            clip-path: polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%);
            font-size: 2rem;
            width: 15%;
            height: 4vh;
            background-color: #8E3B3E;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1020px) {
        width: 20%;
        

    }
`;

export default Sidebar;
