import axios from "axios";
import { decryptData } from "../Functions/Data_protection";

import { secretKey , apiUrl} from "../constant";



export const groupload = async (uri ) => {

    const response = await axios.get(`${apiUrl}${uri}`);
    const decryptedData = decryptData(response.data.payload, secretKey);

    return decryptedData

}

