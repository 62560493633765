import axios from "axios";
import { secretKey, apiUrl } from "../constant";
import { decryptData } from "../Functions/Data_protection";



export const billload = async (uri, reference) => {
    try {
        const response = await axios.get(`${apiUrl}${uri}`, {
            params: { reference},
        });
        if (response.data && response.data.payload) {
            const decryptedData = decryptData(response.data.payload, secretKey);
            return decryptedData;
        } else {
            throw new Error('Invalid response structure');
        }
    } catch (error) {
        console.error('Error loading bill:', error);
        return null;
    }
};
