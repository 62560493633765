import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    federationlist: [],
    selectedfederationlist: [],
};

const FederationSlice = createSlice({
    name: 'federation',
    initialState,
    reducers: {
        setFederationlist: (state, action) => {
            state.federationlist = action.payload;
        },
        setSelectedFederationlist: (state, action) => {
            state.selectedfederationlist = action.payload;
        },
        addFederation: (state, action) => {
            state.federationlist.push(action.payload);
        },
        removeFederation: (state, action) => {

            return {
                ...state,
                federationlist: state.federationlist.filter(federation => federation.hsgfedid !== action.payload.hsgfedid)
            };
        },

        addSelectedFederation: (state, action) => {
            state.selectedfederationlist.push(action.payload);
        },

        removeSelectedFederation: (state, action) => {

            state.selectedfederationlist = state.selectedfederationlist.filter(federation => federation.id !== action.payload.id);
        },
        updateFederation: (state, action) => {
            const index = state.federationlist.findIndex(federation => federation.hsgfedid === action.payload.hsgfedid);
            if (index !== -1) {
                state.federationlist[index] = action.payload;
            } else {
                console.log("Federation not found in the list.");
            }
        },

        updateSelectedFederation: (state, action) => {
            const index = state.selectedfederationlist.findIndex(federation => federation.id === action.payload.id);
            if (index !== -1) {
                state.selectedfederationlist[index] = action.payload;
            }
        },
    },
});

export const {
    setFederationlist,
    setSelectedFederationlist,
    addFederation,
    removeFederation,
    addSelectedFederation,
    removeSelectedFederation,
    updateFederation,
    updateSelectedFederation,
} = FederationSlice.actions;

export default FederationSlice.reducer;
