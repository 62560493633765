import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    admin: "",
    userid : "",
    adminlist: [],
};

const AdminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setAdmin: (state, action) => {
            state.admin = action.payload;
        },
        setAdminId: (state, action) => {
            state.userid = action.payload;
        },
        setAdminList: (state, action) => {
            state.adminlist = action.payload;
        },
        addAdminToList: (state, action) => {
            state.adminlist.push(action.payload);
        },
        removeAdminFromList: (state, action) => {
            state.adminlist = state.adminlist.filter(admin => admin.id !== action.payload.id);
        },
        updateAdminInList: (state, action) => {
            const index = state.adminlist.findIndex(admin => admin.username == action.payload.username);
            if (index !== -1) {
                state.adminlist[index] = action.payload;
            }
        },
    },
});

export const {
    setAdmin,
    setAdminId,
    setAdminList,
    addAdminToList,
    removeAdminFromList,
    updateAdminInList,
} = AdminSlice.actions;

export default AdminSlice.reducer;
