import React, { useState, useEffect, useRef } from 'react';

import Sidebar from '../Sidebar';
import styled from 'styled-components';
import Header from '../Components/Mainpage_componnets/Header';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faArrowRight, faFileUpload, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleExportExcel } from '../Functions/Excelprint';
import { RetrieveDemographicData } from '../Functions/RetrieveDemographic';
import { HandleDelete } from '../api/Deletion';
import { validateForm } from '../Functions/Form_validation';
import { handleAdd } from '../api/Addition';
import { handleFileUpload } from '../Functions/Excelupload';
import { handlefilter } from '../Functions/FilterRecords';
import { useDispatch, useSelector } from 'react-redux';
import { setFederationlist } from '../store/slices/HousingFederationSlice';
import { Bill_head_load } from '../api/Bill_head_load';
import { calculationmethodload } from '../api/Calculation_method_load';
import { addBillhead, setBillheadlist } from '../store/slices/BillheadSlice';
import { updateBillhead } from '../store/slices/BillheadSlice';
import { removeBillhead } from '../store/slices/BillheadSlice';

import { apiUrl } from '../constant';
import axios from 'axios';
import { decryptData } from '../Functions/Data_protection';
import { secretKey } from '../constant';




const Bill_heads = () => {

    const dispatch = useDispatch();

    const selectedbuildingState = useSelector(state => state.building.selectedbuilding);

    const adminidState = useSelector(state => state.admin.userid);


    const [originalRecords, setOriginalRecords] = useState([]);
    const [records, setRecords] = useState([]);
    const [filteredledger, setFilteredledger] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [slideropen, setSlideropen] = useState(false);
    const [ledid, setLedId] = useState('');

    const [combine, setCombine] = useState('');


    const [createdby, setCreatedby] = useState('');
    const [errors, setErrors] = useState({});
    const [modifiedby, setModifiedby] = useState('');
    const [exceldata, setExceldata] = useState([]);
    const [addexcelfilebox, setAddexcelbox] = useState(false);


    const [billheadid, setBillheadid] = useState("");
    const [itemdisplayname, setItemdisplayname] = useState("");
    const [natureoffield, setNatureoffield] = useState(false);
    const [taxable, setTaxable] = useState(false);
    const [undertaxexmp, setUndertaxexmp] = useState(false);
    const [calcmethod, setCalcmethod] = useState("");
    const [calcmethodname, setCalcmethodname] = useState("");
    const [calculationmethodlist, setCalcmethodlist] = useState([]);

    const [ownerrate, setOwnerrate] = useState("");
    const [tenantrate, setTenantrate] = useState("");

    const [chargefreq, setChargefreq] = useState(false);
    const [chargefreqnext, setChargefreqnext] = useState(false);
    const [chargefreqmonth, setChargefreqmonth] = useState(null);
    const [chargefreqstartdate, setChargefreqstartdate] = useState(null);
    const [chargefreqenddate, setChargefreqenddate] = useState(null);
    const [headno, setHeadno] = useState("");



    const [selectedRows, setSelectedRows] = useState([]);


    const [updateclicked, setUpdateclicked] = useState(false);
    const [Deleteclicked, setDeleteclicked] = useState(false);




    const openslider = () => {
        setSlideropen(true);
    };


    const columns = [
        {
            name: '',
            selector: row => (
                <div onClick={() => {
                    setSlideropen(false);
                    setAddexcelbox(false);
                    setDeleteclicked(true);
                    setSelectedRow(row);

                    setBillheadid(row.billheadid);
                }}>
                    <button style={{
                        border: 'none',
                        background: 'none',
                        cursor: 'pointer'
                    }}>
                        <FontAwesomeIcon icon={faClose} style={{ color: '#8E3B3E', fontSize: '1.5rem' }} />
                    </button>
                </div>
            ),
            sortable: false, // This column does not need to be sortable
            width: '50px',
            wrap: true
        },
        { name: 'Item Displayname', selector: row => <div onClick={() => { Updateclicked(row) }}>{row.itemdisplayname}</div>, sortable: true, width: '150px', wrap: true },
        { name: 'Owner Rate', selector: row => row.ownerrate, sortable: true, wrap: true, width: '250px' },
        { name: 'Tenant Rate', selector: row => row.tenantrate, sortable: true, wrap: true, width: '250px' },
        { name: 'Calculation Method', selector: row => row.calcmethod, sortable: true, wrap: true, width: '250px' },
        { name: 'Start Date', selector: row => row.chargefreqstartdate, sortable: true, width: '150px', wrap: true },
        { name: 'End Date', selector: row => row.setChargefreqendate, sortable: true, width: '150px', wrap: true },


    ];


    const ledgerloadfunc = async () => {
        if (selectedbuildingState) {
            try {
                const bldgid = selectedbuildingState.bldgid;
                const response = await axios.get(`${apiUrl}getspecificgroupledgers`, {
                    params: { bldgid, grpid: 4 }
                });

                const decryptedData = decryptData(response.data.payload, secretKey);

                setFilteredledger(decryptedData);


            } catch (error) {
                console.log(error);
            }
        }

    };


    const loadbillheadsfunc = async () => {
        if (selectedbuildingState) {
            const billheadresponse = await Bill_head_load("getbillheads", selectedbuildingState.bldgid);
            dispatch(setBillheadlist(billheadresponse));

            setOriginalRecords(billheadresponse);
            setRecords(billheadresponse);
        }

    }

    const handleload = async () => {
        const calculation_response = await calculationmethodload("getcalculationmethod");
        setCalcmethodlist(calculation_response)
        if (selectedbuildingState) {

            ledgerloadfunc();

            const billheadresponse = await Bill_head_load("getbillheads", selectedbuildingState.bldgid);
            dispatch(setBillheadlist(billheadresponse));
        }
    }

    useEffect(() => {
        handleload();
    }, [])

    useEffect(() => {
        ledgerloadfunc();
    }, [selectedbuildingState]);

    useEffect(() => {
        loadbillheadsfunc();
    }, [selectedbuildingState]);




    const resetControls = () => {
        setItemdisplayname('');
        setNatureoffield(false);
        setTaxable(false);
        setUndertaxexmp(false);
        setCalcmethod('');
        setOwnerrate('');
        setTenantrate('');
        setChargefreq(false);
        setChargefreqnext(false);
        setChargefreqstartdate('');
        setChargefreqenddate('');
        setCombine('');
        setChargefreqmonth('');
        setHeadno('');




    };


    const Updateclicked = (row) => {
        setSelectedRow(row); // Storing the selected row data
        setDeleteclicked(false);
        setSlideropen(true);
        setUpdateclicked(true);



        // populate data to input
        setBillheadid(row.billheadid);
        setItemdisplayname(row.itemdisplayname);
        setNatureoffield(row.natureoffield);
        setTaxable(row.taxable);
        setUndertaxexmp(row.undertaxexmp);
        setOwnerrate(row.ownerrate);
        setTenantrate(row.tenantrate)
        setChargefreq(row.chargefreq);
        setChargefreqnext(row.chargefreqnext);
        setChargefreqstartdate(row.chargefreqstartdate);
        setChargefreqmonth(row.chargefreqmonth);
        setChargefreqenddate(row.chargefreqenddate);
        setCombine(row.combine);
        setHeadno(row.headno);


        setCalcmethod(row.calcmethod)

    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        let payload;


        // Check if exceldata has more than one row or not
        if (exceldata.length > 1) {


            payload = exceldata.map(data => ({


                bldgid: data.bldgid,
                ledid: data.ledid,
                itemdisplayname: data.itemdisplayname,
                natureoffield: data.natureoffield,
                taxable: data.taxable,
                undertaxexmp: data.undertaxexmp,
                calcmethod: data.calcmethod,
                ownerrate: data.ownername,
                tenantrate: data.tenantrate,
                chargefreq: data.chargefreq,
                chargefreqnext: data.chargefreqnext,
                chargefreqstartdate: data.chargefreqstartdate,
                chargefreqenddate: data.setChargefreqendate,
                chargefreqmonth: data.chargefreqmonth,
                combine: data.combine,
                headno: data.headno,
                createdBy: createdby,

            }));
        } else {

            const data = exceldata[0] || {};
            const name = data.itemdisplayname
                ? data.itemdisplayname.toLowerCase().replace(/[^a-z]/g, '')
                : itemdisplayname.toLowerCase().replace(/[^a-z]/g, '');

            payload = {
                billheadid: updateclicked ? billheadid : null,
                bldgid: updateclicked ? null : selectedbuildingState.bldgid,
                ledid: updateclicked ? ledid : null || ledid,
                itemdisplayname: data.itemdisplayname || itemdisplayname,
                natureoffield: data.natureoffield || natureoffield,
                taxable: data.taxable || taxable,
                undertaxexmp: data.undertaxexmp || undertaxexmp,
                calcmethod: data.calcmethod || calcmethod,
                ownerrate: data.ownername || ownerrate,
                tenantrate: data.tenantrate || tenantrate,
                chargefreq: data.chargefreq || chargefreq,
                chargefreqnext: data.chargefreqnext || chargefreqnext,
                chargefreqstartdate: data.chargefreqstartdate || chargefreqstartdate,
                chargefreqenddate: data.setChargefreqendate || chargefreqenddate,
                chargefreqmonth: data.chargefreqmonth || chargefreqmonth,
                combine: data.combine || combine,
                headno: data.headno || headno,
                createdby: updateclicked ? "" : adminidState,
                modifiedby: updateclicked ? adminidState : "",
                fieldname: `bill_${name}`,



            };
        }

        console.log(`This is the payload given:`, payload);


        // let validationErrors = {};
        // if (Array.isArray(payload)) {
        //     validationErrors = payload.map(validateForm).flat();
        // } else {
        //     validationErrors = validateForm(payload);
        // }


        // if (Object.keys(validationErrors).length > 0) {
        //     console.log(validationErrors);
        //     setErrors(validationErrors);
        //     return;
        // }

        setErrors({});

        try {
            const response = await handleAdd(payload, 'addbillhead');
            console.log(response);
            if (response[0].status) {
                toast.success(`${response[0].message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setSlideropen(false);
                setAddexcelbox(false);
                console.log(response);
                if (response[0].update) {
                    console.log("Update Triggered");
                    dispatch(updateBillhead(response[0].data));
                    loadbillheadsfunc();

                }
                else {
                    console.log("Add Triggered");
                    response.forEach(item => {
                        dispatch(addBillhead(item.data));
                        loadbillheadsfunc();
                    });

                }


            }
        } catch (error) {
            console.error(error);
        }
        finally {
            payload = null;
            setExceldata([]);
            resetControls();


        }
    };

    const handleRowSelected = state => {
        const selectedRows = state.selectedRows;
        setSelectedRows(selectedRows);


    };


    const HandleDeleteclicked = async () => {
        const deletedresponsedata = await HandleDelete(billheadid, "deletebillhead");
        if (deletedresponsedata.status) {
            toast.success(deletedresponsedata.message)
            setDeleteclicked(false);
            dispatch(removeBillhead({ billheadid: deletedresponsedata.data }));
            loadbillheadsfunc();

        }
    };










    return (
        <StyledBillHead slideropen={slideropen}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="container">
                <div className="sub-container">
                    <header className='header'>
                        <span id='heading'>Bill Heads</span>
                        <div className="work-buttons">

                            <FontAwesomeIcon id='download-btn' icon={faFileDownload} onClick={() => handleExportExcel(selectedRows)} />

                            <FontAwesomeIcon id='upload-excel-btn' icon={faFileUpload} onClick={() => { setAddexcelbox(true); }} />


                            <input type="text" placeholder='search' id='search' onChange={(event) => { handlefilter(event, originalRecords, setRecords) }} />


                            <button id='add-btn' onClick={() => {
                                setAddexcelbox(false);
                                setDeleteclicked(false);
                                openslider();
                                setUpdateclicked(false);
                                resetControls();

                            }}>Add New

                            </button>


                        </div>
                    </header>
                    <div id="table-component">
                        <DataTable
                            // ref={tableRef}
                            columns={columns}
                            data={records}
                            selectableRows
                            onSelectedRowsChange={handleRowSelected}
                            pagination
                            paginationPerPage={5}
                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                            className='data-table'
                        ></DataTable>
                    </div>


                </div>
                {addexcelfilebox && (
                    <div id="overlayaddexcelbox">
                        <FontAwesomeIcon
                            icon={faClose}
                            id="emailmessageicon"
                            onClick={() => { setAddexcelbox(false); }}
                        />

                        <span>Upload Excel</span>
                        <input style={{ display: 'block', textAlign: 'center', border: '1px solid #8E3B3E' }} type="file" name="" id="" onChange={(e) => { handleFileUpload(e, setExceldata) }} />
                        <button id='excelbox-submit-btn' onClick={handleSubmit}>import</button>

                    </div>
                )}

                {Deleteclicked && (
                    <div className="overlaydltbox">
                        <p>Are u sure want to Delete ?</p>
                        <div className="dltresponseboxes">
                            <button id='dlt-btns' onClick={HandleDeleteclicked} >Delete</button>
                            <button id='dlt-btns' onClick={() => setDeleteclicked(false)} >Cancel</button>
                        </div>
                    </div>
                )}

            </div>
            <div className="slider">
                <div className="icon">
                    <FontAwesomeIcon icon={faClose} id='closeicon' onClick={() => setSlideropen(false)} />
                    <p style={{ marginLeft: '2%' }}>Bill Heads</p>
                </div>
                <form className="slider-form" onSubmit={handleSubmit}>
                    <label htmlFor="Name">Select Ledger:</label>
                    <select
                        style={{ width: '100%', height: '3.5vh', marginBottom: '15px' }}
                        onChange={(e) => setLedId(e.target.value)}
                        disabled={updateclicked}




                    ><option value="">Select ledger</option>
                        {filteredledger.length > 0 &&
                            filteredledger.map((item, index) => (
                                <option
                                    key={index} value={item.ledid}>
                                    {updateclicked ? "" : item.ownername}
                                </option>
                            ))}
                    </select>

                    <label htmlFor="Item-Display-Name">Item-Display-Name</label>

                    <input type="text" placeholder="Enter Display Name" value={itemdisplayname} onChange={(e) => setItemdisplayname(e.target.value)} />

                    <label htmlFor="">Nature of Field:</label>
                    <div style={{ marginBottom: '15px' }}>
                        <input
                            type="radio"
                            name="natureoffield"
                            checked={natureoffield === true}
                            onChange={() => setNatureoffield(true)}
                            style={{ margin: '0', padding: '0', width: '10%' }}
                        />
                        <span>Yes</span>
                        <input
                            type="radio"
                            name="natureoffield"
                            checked={natureoffield === false}
                            onChange={() => setNatureoffield(false)}
                            style={{ margin: '0', padding: '0', width: '10%' }}

                        />
                        <span>No</span>
                    </div>


                    <label htmlFor="">Taxable:</label>
                    <div style={{ marginBottom: '15px' }}>
                        <input
                            type="radio"
                            name="taxable"
                            checked={taxable === true}
                            onChange={() => setTaxable(true)}
                            style={{ margin: '0', padding: '0', width: '10%' }}
                        />
                        <span>Yes</span>
                        <input
                            type="radio"
                            name="taxable"
                            checked={taxable === false}
                            onChange={() => setTaxable(false)}
                            style={{ margin: '0', padding: '0', width: '10%' }}

                        />
                        <span>No</span>
                    </div>


                    <label htmlFor="">Under Tax Excemption:</label>
                    <div style={{ marginBottom: '15px' }}>
                        <input
                            type="radio"
                            name="undertaxexmp"
                            checked={undertaxexmp === true}
                            onChange={() => setUndertaxexmp(true)}
                            style={{ margin: '0', padding: '0', width: '10%' }}
                        />
                        <span>Yes</span>
                        <input
                            type="radio"
                            name="undertaxexmp"
                            checked={undertaxexmp === false}
                            onChange={() => setUndertaxexmp(false)}
                            style={{ margin: '0', padding: '0', width: '10%' }}

                        />
                        <span>No</span>
                    </div>


                    <label htmlFor="Name">Select Calculation Method:</label>
                    <select
                        style={{ width: '100%', height: '3.5vh', marginBottom: '15px' }}
                        value={calcmethod}
                        onChange={(e) => setCalcmethod(e.target.value)}




                    ><option value="">Select Calculation Method</option>
                        {calculationmethodlist.length > 0 &&
                            calculationmethodlist.map((item) => (
                                <option
                                    value={item.srno}>
                                    {item.calcmethod}
                                </option>
                            ))}
                    </select>


                    <label htmlFor="">Owner Rate</label>

                    <input type="number" placeholder="Enter Rate" value={ownerrate} onChange={(e) => setOwnerrate(e.target.value)} />

                    <label htmlFor="">Tenant Rate</label>

                    <input type="number" placeholder="Enter Rate" value={tenantrate} onChange={(e) => setTenantrate(e.target.value)} />

                    <label htmlFor="">Combine</label>

                    <select
                        style={{ width: '100%', height: '3.5vh', marginBottom: '15px' }}
                        onChange={(e) => setCombine(e.target.value)}
                        disabled={updateclicked}
                        value={combine}

                    ><option value="">Select ledger</option>
                        {filteredledger.length > 0 &&
                            filteredledger.map((item, index) => (
                                <option
                                    key={index} value={item.ledid}>
                                    {updateclicked ? "" : item.ownername}
                                </option>
                            ))}
                    </select>

                    <label>Head Number :</label>
                    <input type="number" placeholder='Enter bill head number' value={headno} onChange={(e) => setHeadno(e.target.value)} />




                    <label htmlFor="">Charge Frequency :</label>
                    <div style={{ marginBottom: '15px' }}>
                        <input
                            type="radio"
                            name="chargefreq"
                            checked={chargefreq === true}
                            onChange={() => setChargefreq(true)}
                            style={{ margin: '0', padding: '0', width: '10%' }}
                        />
                        <span>Yes</span>
                        <input
                            type="radio"
                            name="chargefreq"
                            checked={chargefreq === false}
                            onChange={() => setChargefreq(false)}
                            style={{ margin: '0', padding: '0', width: '10%' }}

                        />
                        <span>No</span>
                    </div>



                    {chargefreq === true && (
                        <>
                            <label htmlFor="">Charge Frequency Next :</label>
                            <div style={{ marginBottom: '15px' }}>
                                <input
                                    type="radio"
                                    name="chargefreqnext"
                                    checked={chargefreqnext === true}
                                    onChange={() => setChargefreqnext(true)}
                                    style={{ margin: '0', padding: '0', width: '10%' }}
                                />
                                <span>Yes</span>
                                <input
                                    type="radio"
                                    name="chargefreqnext"
                                    checked={chargefreqnext === false}
                                    onChange={() => setChargefreqnext(false)}
                                    style={{ margin: '0', padding: '0', width: '10%' }}

                                />
                                <span>No</span>
                            </div>


                            <label>Charge frequency start date :</label>
                            <input type="date" onChange={(e) => setChargefreqstartdate(e.target.value)} value={chargefreqstartdate} />


                            <label>Charge frequency month :</label>
                            <input type="number" onChange={(e) => setChargefreqmonth(e.target.value)} value={chargefreqmonth} />


                            <label>Charge frequency end date :</label>
                            <input type="date" id="" onChange={(e) => setChargefreqenddate(e.target.value)} value={chargefreqenddate} />
                        </>
                    )}



                    {updateclicked ? (
                        <button type="submit">Update</button>
                    ) : (
                        <button type="submit">Add</button>
                    )}

                </form>
            </div>
            <Header />

            <Sidebar />
        </StyledBillHead>
    )
}

const StyledBillHead = styled.div`
    .container{
    position: relative;
    left: 15%;
    width: 85%;
    min-height: 100vh;
    padding: 20vh 10%;
   


}

.sub-container{
    width: 100%;
    background-color: white;
    padding: 4vh 5%;
    box-shadow: 0px 4px 8px 0px #E2CACB; 


}

.header{
    display: flex;
    justify-content: space-between;
}

.work-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

   
}

#search{
    padding: 2px 10px;
    height: 4vh;
    border-radius: 10px;
    border: 2px solid #8E3B3E;

}

#search:focus{
    border: 2px solid #8E3B3E;
    outline: none;

}



#add-btn{
    margin-left: 1vw;
    text-align: center;
    height: 4vh;
    border-radius: 10px;
    width: 8vw;
    background-color: #8E3B3E;
    color: #fff;
    border: none;


}

#add-dropbox{
    right: -2%;
    margin-top: 6vh;
    position: absolute;
    width: 8vw;
    border-radius: 10px;
    z-index: 1;
    background-color: yellow;
    background-color: #8E3B3E;
    border: 1px solid #fff;


}

.add-dropbox-btns{
    width: 100%;
    background-color: #8E3B3E;
    border: none;
    border-radius: 10px;
    min-height: 3vh;

    color: #fff;
}


#download-btn{
    margin-right: 1vw;
    border-radius: 10px;
    color: #8E3B3E;
    border: none;
    font-size: 1.8rem;
}

#upload-excel-btn{
    margin-right: 1vw;
    border-radius: 10px;
    color: #8E3B3E;
    border: none;
    font-size: 1.8rem;
}

#send-mail-btn{
    margin-left: 1vw;
    text-align: center;
    height: 4vh;
    border-radius: 10px;
    width: 8vw;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
}

#heading{
    font-size: 2rem;
}



.slider{
    width: 20%;
    height: 100vh;
    position: fixed;
    right: 0;
    display: ${props => (props.slideropen ? 'block' : 'none')};
    transition: transform 0.3s ease-in-out;
    top: 0;
    background-color: #FDD5D6;
    transition: right 0.3s ease;
    z-index: 1;
    overflow-y: scroll;
    padding: 0px 1%;
}

.icon{
    display: flex;
    align-items: center;
    position: relative;
    top: 2vh;
    left: 5%;
    font-size: 1.2rem;

}

    #closeicon{
        
        font-size: 2rem;
        color: #8E3B3E;

    }

    
.slider-form {
    max-width: 400px;
    margin: 5vh auto 0 auto;
}

.slider-form label {
    display: block;
    margin-bottom: 5px;
}

.slider-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.slider-form button {
    width: 100%;
    padding: 10px;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.overlaydltbox{
    position: absolute;
    top: 40vh;
    left: 30%;
    z-index: 2;
    width: 40%;
    height: 20vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 2rem;
    display: grid;
    place-items: center;
}

#overlayaddexcelbox{
    position: absolute;
    top: 30vh;
    left: 40%;
    z-index: 2;
    width: 20%;
    height: 15vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 2rem;
    display: grid;
    place-items: center;
    
}

.dltresponseboxes{
    display: flex;
    justify-content: space-evenly;
    width: 80%;


}

#excelbox-submit-btn{
    width: 40%;
    height: 3vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
    border-radius: 10px;
}

#dlt-btns{
    width: 25%;
    height: 4vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
}

.overlaymessagebox{
    position: absolute;
    top: 40vh;
    left: 35%;
    z-index: 2;
    width: 30%;
    padding: 2vh 0px;
    min-height: 30vh;
    background-color: #FDD5D6;
    display: grid;
    place-items: center;
}

.overlay-message-input{
    width: 80%;
    padding: 2px;
    margin-bottom: 2vh;
    
}

.overlay-email-submit{
    width: 80%;
    height: 4vh;
    background-color: #8E3B3E;
    border: none;
    color: #fff;


}

#emailmessageicon{
    position: absolute;
    top: 1vh;
    left: 2%;
    font-size: 1.8rem;
    color: #8E3B3E;

}

.msg-checkboxes{
    width: 80%;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vh;
}



@media screen and (max-width: 480px) {
        
    .container{
    margin-top: 5vh;
    position: relative;
    left: 0;
    width: 100%;
    min-height: 100vh;
    padding: 5vh 5%;
    overflow-x: hidden;

    }

    
    .work-buttons{
        font-size: 0.7rem;
        justify-content: flex-end;
    }


    #search{
    width: 35%;
    padding: 2px 10px;
    height: 4vh;
    border: 2px solid #8E3B3E;

    }

    #add-btn{
    text-align: center;
    height: 4vh;
    width: 22.5%;
    font-size: 0.7rem;

    
    
    
    }

    #send-mail-btn{
    margin-left: 1vw;
    height: 4vh;
    width: 22.5%;
    font-size: 0.7rem;
    
    
    }

    #heading{
        font-size: 1rem;
    }

    .slider{
        width: 100%;
        z-index: 1;
    }

    .overlaydltbox{
    position: absolute;
    top: 30vh;
    left: 20%;
    z-index: 2;
    width: 60%;
    height: 10vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 1rem;
    display: grid;
    place-items: center;
}



.dltresponseboxes{
    display: flex;
    justify-content: space-evenly;
    width: 80%;


}

#dlt-btns{
    width: 25%;
    height: 3vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
}



}


@media screen and (min-width: 768px) and (max-width: 1020px) 
{
    .container{
        position: relative;
        left: 20%;
        width: 80%;
        min-height: 100vh;
        padding: 20vh 5%;
    


    }



    #heading{
        font-size: 1.5rem;
    }

    .slider{
        width: 40%;
        z-index: 1;
    }

    .overlaydltbox{
    position: absolute;
    top: 40vh;
    left: 25%;
    z-index: 2;
    width: 50%;
    height: 20vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 1.5rem;
    display: grid;
    place-items: center;    

    }

    .dltresponseboxes{
    display: flex;
    justify-content: space-evenly;
    width: 80%;


    }

    #dlt-btns{
    width: 25%;
    height: 4vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
    }

    #search{
    height: 6vh;
    border: 2px solid #8E3B3E;

    }

    #add-btn{
    height: 6vh;
    font-size: 0.8rem;


    }

    #send-mail-btn{
    height: 6vh;
    font-size: 0.8rem;
    width: 30%;
    
    }


}
`

export default Bill_heads