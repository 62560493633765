import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    billheadlist: [],
};

const BillheadSlice = createSlice({
    name: 'billhead',
    initialState,
    reducers: {
        setBillheadlist: (state, action) => {
            state.billheadlist = action.payload;
            console.log(action.payload);
        },

        addBillhead: (state, action) => {
            state.billheadlist.push(action.payload);

            console.log(action.payload);
        },

        updateBillhead: (state, action) => {
            const index = state.billheadlist.findIndex(billhead => billhead.billheadid === action.payload.billheadid);
            if (index !== -1) {
                state.billheadlist[index] = action.payload;
                console.log("Updated billhead:", state.billheadlist[index]);
            }
        },



        removeBillhead: (state, action) => {
            return {
                ...state,
                billheadlist: state.billheadlist.filter(billhead => billhead.billheadid !== action.payload.billheadid)
            };

           
        }
    }
});

// Export the action creators
export const { setBillheadlist, addBillhead, updateBillhead, removeBillhead } = BillheadSlice.actions;

// Export the reducer
export default BillheadSlice.reducer;
