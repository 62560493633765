import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    ledgerlist: [],
};

const LedgerSlice = createSlice({
    name: 'ledger',
    initialState,
    reducers: {
        setLedgerlist: (state , action) => {
            state.ledgerlist = action.payload;
        },
       
        addledger: (state, action) => {
            state.ledgerlist.push(action.payload);
        },
        
        updateledger: (state, action) => {
            const index = state.ledgerlist.findIndex(ledger => ledger.ledid === action.payload.ledid);
            if (index !== -1) {
                state.ledgerlist[index] = action.payload;
            }
        },
      
        removeledger: (state, action) => {
            return {
                ...state,
                ledgerlist: state.ledgerlist.filter(ledger => ledger.ledid !== action.payload.ledid)
            };
        }
    }
});

// Export the action creators
export const {setLedgerlist, addledger, updateledger, removeledger } = LedgerSlice.actions;

// Export the reducer
export default LedgerSlice.reducer;
