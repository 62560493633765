import axios from 'axios';
import { apiUrl } from '../constant';
import { decryptData } from './Data_protection';
import { secretKey } from '../constant';

export const SaveUploadedExcel = async (file, url) => {
    const formData = new FormData();
    formData.append('File', file);

    try {
        // Log the contents of FormData for debugging
        formData.forEach((value, key) => {
            console.log(`${key}:`, value);
        });

        const response = await axios.post(`${apiUrl}${url}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        const decryptedResponse = decryptData(response.data.data, secretKey);

        return decryptedResponse;
    } catch (error) {
        console.error('Error uploading the file:', error);
        throw error;
    }
};
