import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

// Imports for context
import { HamContext } from '../../Context/Ham';
import { setSelectedbuilding } from '../../store/slices/BuildingSlice';

const Header = () => {
    const { sidebarexpanded, setSidebarexpanded } = useContext(HamContext);

    // Imports for slices
    const buildinglistState = useSelector(state => state.building.buildinglist);
    const selectedbuildingState = useSelector(state => state.building.selectedbuilding);

    const dispatch = useDispatch();

    const handleBuildingChange = (e) => {
        const selectedValue = Number(e.target.value);
        const selectedBuilding = buildinglistState.find(item => item.bldgid === selectedValue);
        if (selectedBuilding) {
            dispatch(setSelectedbuilding(selectedBuilding));
        }
    };

    const handleHamClicked = () => {
        setSidebarexpanded(!sidebarexpanded);
    };

    useEffect(() => {
        if (buildinglistState.length === 1) {
            const singleBuilding = buildinglistState[0];
            dispatch(setSelectedbuilding(singleBuilding));
        }
    }, [buildinglistState, dispatch]);

    return (
        <StyledHeader sidebarexpanded={sidebarexpanded}>
            <div className="mobile-nav">
                <FontAwesomeIcon icon={faBars} id="ham" onClick={handleHamClicked} />
            </div>
            <select
                name="building-selector"
                id="building-selector"
                onChange={handleBuildingChange}
                value={selectedbuildingState && selectedbuildingState.bldgid ? selectedbuildingState.bldgid : ''}
            >
                <option value="" disabled>Select a building</option>
                {buildinglistState.length > 0 && (
                    buildinglistState.map((item, index) => (
                        <option key={index} value={item.bldgid}>
                            {item.bldgname}
                        </option>
                    ))
                )}
            </select>
            <select name="lang-selector" id="lang-selector">
                <option value="">English</option>
                <option value="">Hindi</option>
                <option value="">Marathi</option>
            </select>
            <div id="profile">
                <FontAwesomeIcon icon={faUser} />
            </div>
        </StyledHeader>
    );
};

const StyledHeader = styled.div`
    width: 100%;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0;

    #building-selector,
    #lang-selector {
        width: 8%;
        min-height: 30px;
        outline: 0.8px solid #8E3B3E;
        border-radius: 8px;
        margin-right: 1%;
    }

    #profile {
        display: grid;
        place-items: center;
        width: 40px;
        height: 40px;
        background-color: #FDE3CF;
        font-size: 1rem;
        color: #F56A00;
        border-radius: 100%;
        margin-right: 2%;
    }

    #ham {
        font-size: 2rem;
        position: fixed;
        top: 2vh;
        left: 5%;
        display: ${({ sidebarexpanded }) => (sidebarexpanded ? 'none' : 'block')};
    }

    @media screen and (max-width: 480px) {
        #building-selector,
        #lang-selector {
            width: 30%;
            min-height: 30px;
            outline: 0.8px solid #8E3B3E;
            border-radius: 8px;
            margin-right: 2%;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1020px) {
        min-height: 15vh;

        #building-selector,
        #lang-selector {
            width: 20%;
            outline: 0.8px solid #8E3B3E;
            border-radius: 8px;
            margin-right: 2%;
        }
    }
`;

export default Header;
