import React, { useState } from 'react';
import styled from 'styled-components';
import Progressbar from './Progressbar';
import CircularProgressBar from './CircularProgressBar';
import LineChart from './LineChart';

const Dashboard = () => {
    const [percentage, setPercentage] = useState(50);

    return (
        <StyledDashboard>
            <section>
                <div className="gridcontainer">
                    <div className="grid-item">
                        <div className="field">
                            <span>Customers</span>
                        </div>
                        <div className="separator"></div>
                        <div className="result">
                            <span>This month</span>
                            <div className="vertical-separator"></div>
                            <div className="amount-box">
                                <span>₹ 200</span>
                            </div>
                        </div>
                    </div>
                    <div className="grid-item">
                        <div className="field">
                            <span>Sales</span>
                        </div>
                        <div className="separator"></div>
                        <div className="result">
                            <span>This month</span>
                            <div className="vertical-separator"></div>
                            <div className="amount-box">
                                <span>₹ 200</span>
                            </div>
                        </div>
                    </div>
                    <div className="grid-item">
                        <div className="field">
                            <span>Leads</span>
                        </div>
                        <div className="separator"></div>
                        <div className="result">
                            <span>This month</span>
                            <div className="vertical-separator"></div>
                            <div className="amount-box">
                                <span>₹ 200</span>
                            </div>
                        </div>
                    </div>
                    <div className="grid-item">
                        <div className="field">
                            <span>Receivables</span>
                        </div>
                        <div className="separator"></div>
                        <div className="result">
                            <span>This month</span>
                            <div className="vertical-separator"></div>
                            <div className="amount-box">
                                <span>₹ 200</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="gridcontainer" style={{ marginTop: '5vh' }}>
                    <div className="grid-item">
                        <Progressbar percentage={percentage} />
                    </div>
                    <div className="grid-item grid-item-span-2">
                        <LineChart />
                    </div>
                    <div className="grid-item" style={{ display: 'grid', placeItems: 'center', paddingTop: '10px' }}>
                        <CircularProgressBar percentage={percentage} radius={100} />
                    </div>
                </div>
            </section>
        </StyledDashboard>
    );
}

const StyledDashboard = styled.div`
    width: 100%;
    padding: 50px;

    .gridcontainer {
        margin-top: 5vh;
        display: grid;
        grid-template-columns: 21.25% 21.25% 21.25% 21.25%;
        column-gap: 5%;
        padding: 0px 2.5% 0px 2.5%;
    }

    .grid-item {
        min-height: 15vh;
        box-shadow: 0px 4px 8px 0px #E2CACB;
        background-color: white;
    }

    .grid-item-span-2 {
        grid-column: span 2;
    }

    .field {
        height: 7vh;
        display: grid;
        place-items: center;
        font-size: 1.5rem;
    }

    .separator {
        width: 100%;
        height: 1px;
        background-color: #E2CACB;
        margin: 10px 0;
    }

    .vertical-separator {
        width: 1px;
        height: 5vh;
        background-color: #E2CACB;
    }

    .result {
        display: flex;
        justify-content: space-evenly;
    }

    @media screen and (max-width: 768px) {
        .gridcontainer {
            grid-template-columns: 48% 48%;
            column-gap: 4%;
        }

        .grid-item-span-2 {
            grid-column: span 2;
        }
    }

    @media screen and (max-width: 480px) {
        padding: 20px;

        .gridcontainer {
            grid-template-columns: 100%;
            row-gap: 20px;
            column-gap: 0;
            padding: 0 5% 0px 5%;
            
        }

        .grid-item-span-2 {
            grid-column: span 1;
        }

        .field {
            font-size: 1.2rem;
            height: 5vh;
        }

        .grid-item {
        min-height: 12vh;
        box-shadow: 0px 4px 8px 0px #E2CACB;
        background-color: white;
    }
    }

    #searchbox {
        height: 3vh;
        padding: 4px;
        border: 2px solid transparent;
        box-shadow: 0px 4px 8px 0px #E2CACB;
        outline: none;
        transition: border-color 0.3s;
    }

    #searchbox:focus {
        border-color: #8E3B3E;
    }
`;

export default Dashboard;
