import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session'; 
import adminReducer from '../store/slices/AdminSlice';
import federationReducer from '../store/slices/HousingFederationSlice';
import buildingReducer from '../store/slices/BuildingSlice'
import ledgerReducer from '../store/slices/LedgerSlice'
import billheadReducer from '../store/slices/BillheadSlice'
import VoucherReducer from '../store/slices/VoucherSlice'


const persistConfig = {
    key: 'root',
    storage: sessionStorage, 
};


const rootReducer = combineReducers({
    admin: adminReducer,
    federation: federationReducer,
    building : buildingReducer,
    ledger : ledgerReducer,
    billhead : billheadReducer,
    voucher : VoucherReducer,

});


const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
    reducer: persistedReducer,
});


const persistor = persistStore(store);

export { store, persistor };
