import React, { createContext, useState } from 'react';

export const HamContext = createContext(null);

export const HamProvider = (props) => {
    const [sidebarexpanded, setSidebarexpanded] = useState(false);
    
    return (
        <HamContext.Provider value={{ sidebarexpanded, setSidebarexpanded }}>
            {props.children}
        </HamContext.Provider>
    );
};
