import React, { createContext, useState } from 'react';

export const AddsliderContext = createContext(null);

export const AddsliderProvider = (props) => {
    const [slideropen, setSlideropen] = useState(false);
    
    return (
        <AddsliderContext.Provider value={{ slideropen, setSlideropen }}>
            {props.children}
        </AddsliderContext.Provider>
    );
};
