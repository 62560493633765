
import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { secretKey, apiUrl } from "../constant";
import { decryptData } from "../Functions/Data_protection";

import Sidebar from '../Sidebar';
import styled from 'styled-components';
import Header from '../Components/Mainpage_componnets/Header';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faArrowRight, faFileUpload, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleExportExcel } from '../Functions/Excelprint';
import { RetrieveDemographicData } from '../Functions/RetrieveDemographic';
import { HandleDelete } from '../api/Deletion';
import { validateForm } from '../Functions/Form_validation';
import { handleAdd } from '../api/Addition';
import { Voucher_load } from '../api/Voucherload';
import { handleFileUpload } from '../Functions/Excelupload';
import { handlefilter } from '../Functions/FilterRecords';
import { useDispatch, useSelector } from 'react-redux';

import { setVoucherlist } from '../store/slices/VoucherSlice';
import { addVoucher } from '../store/slices/VoucherSlice';
import { updateVoucher } from '../store/slices/VoucherSlice';
import { removeVoucher } from '../store/slices/VoucherSlice';
import { billload } from '../api/billload';

const Voucher = () => {
    // redux states
    const adminidState = useSelector(state => state.admin.userid);

    const voucherlistState = useSelector(state => state.voucher.voucherlist);

    const selectedbuildingState = useSelector(state => state.building.selectedbuilding);

    const ledgerlistState = useSelector(state => state.ledger.ledgerlist);



    const dispatch = useDispatch();


    const [originalRecords, setOriginalRecords] = useState([]);
    const [records, setRecords] = useState([]);
    const [slideropen, setSlideropen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [exceldata, setExceldata] = useState([]);
    const [addexcelfilebox, setAddexcelbox] = useState(false)

    const [selectedRows, setSelectedRows] = useState([]);
    const [filteredledger, setFilteredledger] = useState([]);


    const [updateclicked, setUpdateclicked] = useState(false);
    const [Deleteclicked, setDeleteclicked] = useState(false);

    const [addbtnclicked, setAddbtnclicked] = useState(false);

    const [voucherid, setVoucherid] = useState("");

    const [ledid, setLedid] = useState("");

    const [billguid, setBillguid] = useState("");

    const [bldgid, setBldgid] = useState("");

    const [vouchertype, setVouchertype] = useState("");

    const [netpay, setNetpay] = useState("");

    const [amountpaid, setAmountpaid] = useState("");

    const [paymode, setPaymode] = useState("");

    // States for onlinepayment mode
    const [onlinepayment, setOnlinepayment] = useState(false);

    const [transactionid, setTransactionid] = useState("");

    const [paymentdate, setPaymentdate] = useState("");

    const [transactionstatus, setTransactionstatus] = useState("");

    const [outputlog, setOutputlog] = useState("");

    const [inputlog, setInputlog] = useState("");

    const [merchantid, setMerchantid] = useState("");

    // States for Cash mode
    const [cashpayment, setCashpayment] = useState(false);

    const [cashreceivedby, setCashreceivedby] = useState("");

    // States for cheque
    const [chequepayment, setChequepayment] = useState(false);

    const [chequeno, setChequeno] = useState("");

    const [chequebankname, setChequebankname] = useState("");


    const [chequeamount, setChequeamount] = useState("");

    const [chequestatus, setChequestatus] = useState("");

    const [chequecharges, setChequecharges] = useState("");

    const [inputValue, setInputValue] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);

    const [suggestionlist, setSuggestionlist] = useState([]);

    // This state holds the previousbills for selected ledger
    const [previousbills, setPreviousbills] = useState([]);
    const [lastfivevouchers, setLastfivevouchers] = useState([]);

    // This states is used for holding the flatdata while updating
    const [flatdata, setFlatdata] = useState({});


    const openslider = () => {
        setSlideropen(true);
    };



    const columns = [
        {
            name: '',
            selector: row => (
                <div onClick={() => {
                    setSlideropen(false);
                    setAddexcelbox(false);
                    setDeleteclicked(true);
                    setSelectedRow(row);
                    setVoucherid(row.voucherid);
                }}>
                    <button style={{
                        border: 'none',
                        background: 'none',
                        cursor: 'pointer'
                    }}>
                        <FontAwesomeIcon icon={faClose} style={{ color: '#8E3B3E', fontSize: '1.5rem' }} />
                    </button>
                </div>
            ),
            sortable: false,
            width: '50px',
            wrap: true
        },
        {
            name: 'Voucher Number',
            selector: row => <div onClick={() => { Updateclicked(row) }}>{row.voucherid}</div>,
            sortable: true,
            width: '150px',
            wrap: true
        },
        {
            name: 'Payment Mode',
            selector: row => row.paymode,
            sortable: true,
            wrap: true,
            width: '150px'
        },
        {
            name: 'Amount Paid',
            selector: row => row.amountpaid,
            sortable: true,
            width: '150px',
            wrap: true
        }

    ];


    const ledgerfilterfunc = async () => {
        try {
            const bldgid = selectedbuildingState.bldgid;
            const response = await axios.get(`${apiUrl}getspecificgroupledgers`, {
                params: { bldgid, grpid: 3 }
            });

            const decryptedData = decryptData(response.data.payload, secretKey);

            setFilteredledger(decryptedData);
            console.log(decryptedData);


        } catch (error) {
            console.log(error);
        }
    };

    const handleload = async () => {
        if (selectedbuildingState) {
            let bldgid = selectedbuildingState.bldgid;
            const voucherresponse = await axios.get(`${apiUrl}getspecificbuildingvouchers`, {
                params: { bldgid: bldgid }
            })

            const decryptedData = decryptData(voucherresponse.data.payload, secretKey);
            dispatch(setVoucherlist(decryptedData))
            setRecords(decryptedData);
            setOriginalRecords(decryptedData);

        }

    }


    useEffect(() => {
        setRecords(voucherlistState);
        setOriginalRecords(voucherlistState);
    }, [voucherlistState])


    useEffect(() => {
        handleload();
    }, [])

    useEffect(() => {
        ledgerfilterfunc();
    }, [selectedbuildingState])



    function convertISOToDate(isoDate) {

        const date = new Date(isoDate);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();

        // Return the formatted date string
        return `${day}-${month}-${year}`;
    }


    function convertToDate(dateString) {
        const [day, month, year] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day);
    }

    function convertToYYYYMMDD(dateString) {
        // Extract the day, month, and year from the date string
        console.log(dateString);
        const day = dateString.slice(0, 2);
        const month = dateString.slice(3, 5);
        const year = dateString.slice(6, 10);

        console.log(year, month, day);

        // Return the formatted date string in yyyy-mm-dd format
        return `${year}-${month}-${day}`;
    }


    // this function retrieves the selected ledger previous bill details

    const getdetails = async (ledid) => {
        try {
            const response = await axios.get(`${apiUrl}getindividualbill`, {
                params: { reference: selectedbuildingState.apprevname, ledid: ledid },
            });
            if (response.data && response.data.payload) {
                const decryptedData = decryptData(response.data.payload, secretKey);

                setPreviousbills(decryptedData);
            } else {
                throw new Error('Invalid response structure');
            }

            const voucherresponse = await axios.get(`${apiUrl}getlastfivevouchers`, {
                params: { ledid: ledid }
            })
            const decryptedData = decryptData(voucherresponse.data.payload, secretKey);
            setLastfivevouchers(decryptedData);

        } catch (error) {
            console.error('Error loading bill:', error);
        }

    }

    // Function to handle input change
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        if (e.target.value.length > 0) {
            setShowSuggestions(true);
            const filtered = filteredledger.filter(item => item.flatshopno.toLowerCase().includes(e.target.value.toLowerCase()));
            setSuggestionlist(filtered);
        } else {
            setShowSuggestions(false);
        }
    };

    // Function to handle selecting a suggestion
    const handleSuggestionClick = (item) => {
        console.log(item);
        getdetails(item.ledid);
        setLedid(item.ledid);
        setInputValue(`${item.wingno}/${item.flatshopno} - ${item.ownername}`);
        setShowSuggestions(false);
    };


    const resetControls = () => {
        setVoucherid('');
        setLedid('');
        setBillguid('');
        setBldgid('');
        setNetpay('');
        setAmountpaid('');
        setPaymode('');
        setOnlinepayment(false);
        setTransactionid('');
        setTransactionstatus('');
        setOutputlog('');
        setInputlog('');
        setMerchantid('');
        setCashreceivedby('');
        setChequeno('');
        setPaymentdate('');
        setChequebankname('');
        setChequeamount('');
        setChequestatus('');
        setChequecharges('');
        setOnlinepayment(false);
        setChequepayment(false);
        setCashpayment(false);
        setInputValue("");
        setPreviousbills("");
        setFlatdata({});



    };

    const findflat = (ledid) => {
        let flat = {};
        ledgerlistState.forEach((item) => {
            if (item.ledid == ledid) {
                flat = item;
            }
        })
        return flat
    }


    const Updateclicked = (row) => {
        setSelectedRow(row); // Storing the selected row data
        setDeleteclicked(false);
        setSlideropen(true);
        setUpdateclicked(true);

        setFlatdata(findflat(row.ledid));


        // populate data to input
        setLedid(row.ledid);
        setVoucherid(row.voucherid);
        setVouchertype(row.vouchertype);
        setAmountpaid(row.amountpaid);
        setPaymode(row.paymode);
        setTransactionid(row.transactionid);
        if (row.paymentdate) {
            setPaymentdate(convertToYYYYMMDD(convertISOToDate(row.paymentdate)))
        }

        setTransactionstatus(row.transactionstatus);
        setMerchantid(row.merchantid);
        setCashreceivedby(row.cashreceivedby);
        setChequeno(row.chequeno);
        setChequebankname(row.chequebankname);
        setChequeamount(row.chequeamount);
        setChequestatus(row.chequestatus);
        setChequecharges(row.chequecharges);



    };




    const handleSubmit = async (e) => {
        e.preventDefault();
        let payload;

        // Check if exceldata has more than one row or not
        if (exceldata.length > 1) {
            payload = exceldata.map(data => ({
                ledid: data.ledid,
                billguid: data.billguid,
                bldgid: data.bldgid,
                vouchertype: data.vouchertype,
                netpay: data.netpay,
                amountpaid: data.amountpaid,
                paymode: data.paymode,
                paymentdate: convertToDate(data.paymentdate),
                monthplusyear: data.monthplusyear,
                ...(data.transactionid && {
                    transactionid: data.transactionid,
                    transactionstatus: data.transactionstatus,
                    merchantid: data.merchantid,

                }),

                ...(data.cashreceivedby && {
                    cashreceivedby: data.cashreceivedby,
                }),

                ...(data.chequeno && {
                    chequeno: data.chequeno,
                    chequebankname: data.chequebankname,
                    chequeamount: data.chequeamount,
                    chequestatus: data.chequestatus,
                    chequecharges: data.chequecharges,
                })
            }));
        } else {

            const data = exceldata[0] || {};
            console.log(data.transactiondate);
            payload = {
                voucherid: updateclicked ? voucherid : "",
                ledid: data.ledid || ledid,
                billguid: data.billguid || billguid,
                bldgid: data.bldgid || selectedbuildingState.bldgid,
                netpay: data.netpay || netpay,
                vouchertype: data.vouchertype || vouchertype,
                amountpaid: data.amountpaid || amountpaid,
                paymode: data.paymode || paymode,
                // monthplusyear : data.monthplusyear || monthplusyear,
                transactionid: data.transactionid || transactionid,
                paymentdate: data.paymentdate ? convertToDate(data.paymentdate) : "" || paymentdate,
                transactionstatus: data.transactionstatus || transactionstatus,
                merchantid: data.merchantid || merchantid,
                cashreceivedby: data.cashreceivedby || cashreceivedby,
                chequeno: data.chequeno || chequeno,
                chequebankname: data.chequebankname || chequebankname,
                chequeamount: data.chequeamount || chequeamount,
                chequestatus: data.chequestatus || chequestatus,
                chequecharges: data.chequecharges || chequecharges,






            };
        }

        console.log(`This is the payload given:`, payload);


        // let validationErrors = {};
        // if (Array.isArray(payload)) {
        //     validationErrors = payload.map(validateForm).flat();
        // } else {
        //     validationErrors = validateForm(payload);
        // }


        // if (Object.keys(validationErrors).length > 0) {
        //     console.log(validationErrors);
        //     setErrors(validationErrors);
        //     return;
        // }

        // setErrors({});

        try {
            const response = await handleAdd(payload, 'addvoucher');
            console.log(response);
            if (response[0].status) {
                toast.success(`${response[0].message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setSlideropen(false);
                setAddexcelbox(false);
                console.log(response);
                if (response[0].update) {
                    console.log("Update Triggered");
                    dispatch(updateVoucher(response[0].data));

                }
                else {
                    console.log("Add Triggered");
                    response.forEach(item => {
                        dispatch(addVoucher(item.data));
                    });

                }


            }
        } catch (error) {
            console.error(error);
        }
        finally {
            payload = null;
            setExceldata([]);
            resetControls();

        }
    };

    const handleRowSelected = state => {
        const selectedRows = state.selectedRows;
        setSelectedRows(selectedRows);


    };


    const HandleDeleteclicked = async () => {
        const deletedresponsedata = await HandleDelete(voucherid, "deletevoucher");
        if (deletedresponsedata.status) {
            toast.success(deletedresponsedata.message)
            setDeleteclicked(false);
            dispatch(removeVoucher({ voucherid: deletedresponsedata.data }));
        }
    };




    return (
        <StyledVoucher slideropen={slideropen}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="container">
                <div className="sub-container">
                    <header className='header'>
                        <span id='heading'>Vouchers</span>
                        <div className="work-buttons">

                            <FontAwesomeIcon id='download-btn' icon={faFileDownload} onClick={() => handleExportExcel(selectedRows)} />

                            <FontAwesomeIcon id='upload-excel-btn' icon={faFileUpload} onClick={() => { setAddexcelbox(true); setAddbtnclicked(false) }} />


                            <input type="text" placeholder='search' id='search' onChange={(event) => { handlefilter(event, originalRecords, setRecords) }} />


                            <button id='add-btn' onClick={() => {
                                resetControls();
                                setAddexcelbox(false);
                                setDeleteclicked(false);
                                setUpdateclicked(false);
                                openslider();

                            }}>Add New

                            </button>

                        </div>
                    </header>
                    <div id="table-component">
                        <DataTable
                            // ref={tableRef}
                            columns={columns}
                            data={records}
                            selectableRows
                            onSelectedRowsChange={handleRowSelected}
                            pagination
                            paginationPerPage={5}
                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                            className='data-table'
                        ></DataTable>
                    </div>


                </div>
                {addexcelfilebox && (
                    <div id="overlayaddexcelbox">
                        <FontAwesomeIcon
                            icon={faClose}
                            id="emailmessageicon"
                            onClick={() => { setAddexcelbox(false); }}
                        />

                        <span>Upload Excel</span>
                        <input style={{ display: 'block', textAlign: 'center', border: '1px solid #8E3B3E' }} type="file" name="" id="" onChange={(e) => { handleFileUpload(e, setExceldata) }} />
                        <button id='excelbox-submit-btn' onClick={handleSubmit}>import</button>

                    </div>
                )}

                {Deleteclicked && (
                    <div className="overlaydltbox">
                        <p>Are u sure want to Delete ?</p>
                        <div className="dltresponseboxes">
                            <button id='dlt-btns' onClick={HandleDeleteclicked} >Delete</button>
                            <button id='dlt-btns' onClick={() => setDeleteclicked(false)} >Cancel</button>
                        </div>
                    </div>
                )}

            </div>
            <div className="slider">
                <div className="icon">
                    <FontAwesomeIcon icon={faClose} id='closeicon' onClick={() => { setSlideropen(false); resetControls() }} />
                    <p style={{ marginLeft: '2%' }}>Vouchers</p>
                </div>
                <form className="slider-form">

                    <label htmlFor="Name">Select Flat / Shop Number:</label>
                    <input
                        type="text"
                        value={updateclicked ? `${flatdata.wingno}/${flatdata.flatshopno} ${flatdata.ownername}` : inputValue}
                        onChange={handleInputChange}
                        disabled={updateclicked}
                        placeholder="Select ledger"
                        style={{ width: '100%', height: '3.5vh', marginBottom: '15px' }}

                    />

                    {showSuggestions && (
                        <div id="suggestions-container" style={{ position: 'absolute', width: '100%', border: '1px solid #ccc', backgroundColor: 'white', zIndex: '1000' }}>
                            {suggestionlist.length > 0 ? (
                                suggestionlist.map((item, index) => (
                                    <div
                                        key={index}
                                        className="suggestion"
                                        style={{ padding: '10px', cursor: 'pointer' }}
                                        onClick={() => handleSuggestionClick(item)}
                                    >
                                        {updateclicked ? "" : `${item.wingno}/${item.flatshopno} - ${item.ownername}`}
                                    </div>
                                ))
                            ) : (
                                <div style={{ padding: '10px' }}>No suggestions available</div>
                            )}
                        </div>
                    )}

                    <label>Voucher Type :</label>
                    <select style={{ width: '100%', height: '3.5vh', marginBottom: '15px' }}
                        value={updateclicked ? vouchertype : null}
                        onClick={(e) => setVouchertype(e.target.value)}>
                        <option value="">Select Type</option>
                        <option value="1">Maintenance Bill</option>
                    </select>


                    <label htmlFor="">Amount Paid :</label>
                    <input type="Number" placeholder='Enter Amount Paid' onChange={(e) => setAmountpaid(e.target.value)}
                        value={amountpaid} />

                    <label htmlFor="">Payment Mode :</label>
                    <div style={{ marginBottom: '15px' }}>
                        <input
                            type="radio"
                            name="paymentmode"
                            checked={paymode == "Online"}
                            onChange={() => { setPaymode("Online") }}
                            style={{ margin: '0', padding: '0', width: '10%' }}
                        />
                        <span>Online</span>
                        <input
                            type="radio"
                            name="paymentmode"
                            checked={paymode == "Cash"}
                            onChange={() => { setPaymode("Cash") }}
                            style={{ margin: '0', padding: '0', width: '10%' }}

                        />
                        <span>Cash</span>
                        <input
                            type="radio"
                            name="paymentmode"
                            checked={paymode == "Cheque"}
                            onChange={() => { setPaymode("Cheque") }}
                            style={{ margin: '0', padding: '0', width: '10%' }}

                        />
                        <span>Cheque</span><br /><br />
                        {paymode && paymode == "Online" && (
                            <>
                                <label htmlFor="">Transaction Id :</label>
                                <input type="text" placeholder='Enter Transaction Id'
                                    value={transactionid}
                                    onChange={(e) => setTransactionid(e.target.value)} />
                                <label htmlFor="">Transaction Date :</label>

                                <input type="date" value={paymentdate}
                                    onChange={(e) => setPaymentdate(e.target.value)}
                                />

                                <label htmlFor="">Transaction Status :</label>

                                <select
                                    style={{ width: '100%', height: '3.5vh', marginBottom: '15px' }}
                                    onChange={(e) => setTransactionstatus(e.target.value)}
                                    value={transactionstatus}
                                >
                                    <option value="">Select Transaction Status</option>
                                    <option value="completed">Completed</option>
                                    <option value="pending">Pending</option>
                                    <option value="failed">Failed</option>

                                </select>
                                <label htmlFor="">Merchant Id :</label>
                                <input type="number"
                                    placeholder='Enter Merchant Id' onChange={(e) => setMerchantid(e.target.value)}
                                    value={merchantid} />
                            </>
                        )}

                        {paymode && paymode == "Cash" && (
                            <>
                                <label htmlFor="">Cash Received By :</label>
                                <input type="text" placeholder='Enter Cash Received By'
                                    onChange={(e) => setCashreceivedby(e.target.value)}
                                    value={cashreceivedby}
                                />

                                <label htmlFor="">Cash Received Date</label>

                                <input type="date"
                                    value={paymentdate} onChange={(e) => setPaymentdate(e.target.value)}
                                />

                            </>
                        )}

                        {paymode && paymode == "Cheque" && (
                            <>
                                <label htmlFor="">Cheque Number
                                    :</label>
                                <input type="Number" placeholder='Enter Cheque Number'
                                    value={chequeno}
                                    onChange={(e) => setChequeno(e.target.value)} />

                                <label htmlFor="">Cheque Bank Name :</label>
                                <input type="text" placeholder='Enter Cheque Bank Name '
                                    value={chequebankname}
                                    onChange={(e) => setChequebankname(e.target.value)}

                                />

                                <label htmlFor="">Cheque Date :</label>

                                <input type="date"
                                    value={paymentdate}
                                    onChange={(e) => setPaymentdate(e.target.value)} />

                                <label htmlFor="">Cheque Amount : </label>

                                <input type="number"
                                    value={chequeamount}
                                    placeholder='Enter Cheque Amount ' onChange={(e) => setChequeamount(e.target.value)} />

                                <label htmlFor="">Cheque Status :</label>

                                <select
                                    style={{ width: '100%', height: '3.5vh', marginBottom: '15px' }}
                                    value={chequestatus}
                                    onChange={(e) => setChequestatus(e.target.value)}>

                                    <option value="">Select Status</option>

                                    <option value="cleared">Cleared</option>

                                    <option value="bounced">Bounced</option>

                                </select>

                                <label htmlFor="">Cheque Charges :</label>

                                <input type="number"
                                    placeholder='Enter Cheque Charges '
                                    value={chequecharges}
                                    onChange={(e) => setChequecharges(e.target.value)} />


                            </>
                        )}

                        <button onClick={(e) => {
                            handleSubmit(e); resetControls()
                        }}>Add</button>

                    </div>

                </form>
                {previousbills && previousbills.length > 0 && (
                    <section id='details-box'>
                        <div className="box" id='box1'>
                            <h3>Latest Bill :-</h3>
                            <table className='details-table'>
                                <tr>
                                    <th>Bill No</th>
                                    <th>Due Date</th>
                                    <th>NetPay</th>
                                    <th>Interest</th>
                                </tr>
                                <tr>
                                    <td>{previousbills[0].billno}</td>
                                    <td>{convertISOToDate(previousbills[0].duedate)}</td>
                                    <td>{previousbills[0].netpayable}</td>
                                    <td>{previousbills[0].sumofinterest}</td>


                                </tr>

                            </table>
                        </div>

                        <div className="box" id='box2'>
                            <h3>Recent Transactions :-</h3>
                            <table className='details-table'>
                                <thead>
                                    <tr>
                                        <th>Voucher No</th>
                                        <th>Payment Date</th>
                                        <th>Payment Amount</th>
                                        <th>Pay Mode</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lastfivevouchers && lastfivevouchers.length > 0 && (
                                        lastfivevouchers.map((item) => (
                                            <tr key={item.voucherid}>
                                                <td>{item.voucherid}</td>
                                                <td>{convertISOToDate(item.paymentdate)}</td>
                                                <td>{item.amountpaid}</td>
                                                <td>{item.paymode}</td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>




                    </section>)}

            </div >
            <Header />
            <Sidebar />
        </StyledVoucher >

    )
}

const StyledVoucher = styled.div`
.container{
    position: relative;
    left: 15%;
    width: 85%;
    min-height: 100vh;
    padding: 20vh 10%;
   


}

.sub-container{
    width: 100%;
    background-color: white;
    padding: 4vh 5%;
    box-shadow: 0px 4px 8px 0px #E2CACB; 


}

.header{
    display: flex;
    justify-content: space-between;
}

.work-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

   
}

#search{
    padding: 2px 10px;
    height: 4vh;
    border-radius: 10px;
    border: 2px solid #8E3B3E;

}

#search:focus{
    border: 2px solid #8E3B3E;
    outline: none;

}



#add-btn{
    margin-left: 1vw;
    text-align: center;
    height: 4vh;
    border-radius: 10px;
    width: 8vw;
    background-color: #8E3B3E;
    color: #fff;
    border: none;


}

#add-dropbox{
    right: -2%;
    margin-top: 6vh;
    position: absolute;
    width: 8vw;
    border-radius: 10px;
    z-index: 1;
    background-color: yellow;
    background-color: #8E3B3E;
    border: 1px solid #fff;


}

.add-dropbox-btns{
    width: 100%;
    background-color: #8E3B3E;
    border: none;
    border-radius: 10px;
    min-height: 3vh;

    color: #fff;
}


#download-btn{
    margin-right: 1vw;
    border-radius: 10px;
    color: #8E3B3E;
    border: none;
    font-size: 1.8rem;
}

#upload-excel-btn{
    margin-right: 1vw;
    border-radius: 10px;
    color: #8E3B3E;
    border: none;
    font-size: 1.8rem;
}

#send-mail-btn{
    margin-left: 1vw;
    text-align: center;
    height: 4vh;
    border-radius: 10px;
    width: 8vw;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
}

#heading{
    font-size: 2rem;
}



.slider{
    width: 20%;
    height: 100vh;
    position: absolute;
    right: 0;
    display: ${props => (props.slideropen ? 'block' : 'none')};
    transition: transform 0.3s ease-in-out;
    top: 0;
    background-color: #FDD5D6;
    transition: right 0.3s ease;
    z-index: 1;
    overflow-y: scroll;
    padding: 0px 1%;
}

.icon{
    display: flex;
    align-items: center;
    position: relative;
    top: 2vh;
    left: 5%;
    font-size: 1.2rem;

}

    #closeicon{
        
        font-size: 2rem;
        color: #8E3B3E;

    }

    
.slider-form {
    max-width: 400px;
    margin: 5vh auto 0 auto;
}

.slider-form label {
    display: block;
    margin-bottom: 5px;
}

.slider-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.slider-form button {
    width: 100%;
    padding: 10px;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.overlaydltbox{
    position: absolute;
    top: 40vh;
    left: 30%;
    z-index: 2;
    width: 40%;
    height: 20vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 2rem;
    display: grid;
    place-items: center;
}

#overlayaddexcelbox{
    position: absolute;
    top: 30vh;
    left: 40%;
    z-index: 2;
    width: 20%;
    height: 15vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 2rem;
    display: grid;
    place-items: center;
    
}

.dltresponseboxes{
    display: flex;
    justify-content: space-evenly;
    width: 80%;


}

#excelbox-submit-btn{
    width: 40%;
    height: 3vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
    border-radius: 10px;
}

#dlt-btns{
    width: 25%;
    height: 4vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
}

.overlaymessagebox{
    position: absolute;
    top: 40vh;
    left: 35%;
    z-index: 2;
    width: 30%;
    padding: 2vh 0px;
    min-height: 30vh;
    background-color: #FDD5D6;
    display: grid;
    place-items: center;
}

.overlay-message-input{
    width: 80%;
    padding: 2px;
    margin-bottom: 2vh;
    
}

.overlay-email-submit{
    width: 80%;
    height: 4vh;
    background-color: #8E3B3E;
    border: none;
    color: #fff;


}

#emailmessageicon{
    position: absolute;
    top: 1vh;
    left: 2%;
    font-size: 1.8rem;
    color: #8E3B3E;

}

.msg-checkboxes{
    width: 80%;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vh;
}

#details-box{
    width: 100%;
    padding: 5%;
    background-color: white;

}

#details-box > #box1{
    margin-bottom: 2vh;
}



.details-table {
    border: 1.5px solid #8E3B3E;
    border-collapse: collapse;
    width: 100%;

}

.details-table > tr , td , th{
    border: 1.5px solid #8E3B3E;
}

.details-table > th , td {
font-size: 1rem;
  text-align: center;

} 











@media screen and (max-width: 480px) {
        
    .container{
    margin-top: 5vh;
    position: relative;
    left: 0;
    width: 100%;
    min-height: 100vh;
    padding: 5vh 5%;
    overflow-x: hidden;

    }

    
    .work-buttons{
        font-size: 0.7rem;
        justify-content: flex-end;
    }


    #search{
    width: 35%;
    padding: 2px 10px;
    height: 4vh;
    border: 2px solid #8E3B3E;

    }

    #add-btn{
    text-align: center;
    height: 4vh;
    width: 22.5%;
    font-size: 0.7rem;

    
    
    
    }

    #send-mail-btn{
    margin-left: 1vw;
    height: 4vh;
    width: 22.5%;
    font-size: 0.7rem;
    
    
    }

    #heading{
        font-size: 1rem;
    }

    .slider{
        width: 100%;
        z-index: 1;
    }

    .overlaydltbox{
    position: absolute;
    top: 30vh;
    left: 20%;
    z-index: 2;
    width: 60%;
    height: 10vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 1rem;
    display: grid;
    place-items: center;
}



.dltresponseboxes{
    display: flex;
    justify-content: space-evenly;
    width: 80%;


}

#dlt-btns{
    width: 25%;
    height: 3vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
}



}


@media screen and (min-width: 768px) and (max-width: 1020px) 
{
    .container{
        position: relative;
        left: 20%;
        width: 80%;
        min-height: 100vh;
        padding: 20vh 5%;
    


    }



    #heading{
        font-size: 1.5rem;
    }

    .slider{
        width: 40%;
        z-index: 1;
    }

    .overlaydltbox{
    position: absolute;
    top: 40vh;
    left: 25%;
    z-index: 2;
    width: 50%;
    height: 20vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 1.5rem;
    display: grid;
    place-items: center;    

    }

    .dltresponseboxes{
    display: flex;
    justify-content: space-evenly;
    width: 80%;


    }

    #dlt-btns{
    width: 25%;
    height: 4vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
    }

    #search{
    height: 6vh;
    border: 2px solid #8E3B3E;

    }

    #add-btn{
    height: 6vh;
    font-size: 0.8rem;



    

    }

    #send-mail-btn{
    height: 6vh;
    font-size: 0.8rem;
    width: 30%;

    
    
    }


}
`
export default Voucher