import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HamProvider } from './Context/Ham';
import { AddsliderProvider } from './Context/Addslider';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './store/store';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HamProvider>


          <AddsliderProvider>
            <App />
          </AddsliderProvider>


        </HamProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode >
);


