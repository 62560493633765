import axios from "axios";
import { encryptData } from "../Functions/Data_protection";
import { decryptData } from "../Functions/Data_protection";
import { secretKey , apiUrl } from "../constant";



export const HandleDelete = async ( id,url) => {
    const payload = {
        id
    };
    const encryptedPayload = encryptData(payload, secretKey);
    try {
        const response = await axios.post(`${apiUrl}${url}`, { payload: encryptedPayload });
        const decryptedResponse = decryptData(response.data.payload, secretKey);
        return decryptedResponse;
        
    } catch (error) {
        console.log(error);
    }





}