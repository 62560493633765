import React from 'react'
import { useState, useEffect } from 'react';
import styled from 'styled-components';

const Progressbar = ({ percentage }) => {
    const [displayPercentage, setDisplayPercentage] = useState(0);

    useEffect(() => {
        setDisplayPercentage(percentage);
    }, [percentage]);

    return (
        <StyledProgressbar>
            <div className="progress-bar-texts">
                <span className="progress-bar-text">Sales</span>
                <span className="progress-bar-text">{displayPercentage}%</span>

            </div>
            <div className="progress-bar">
                <div className="progress-bar-fill" style={{ width: `${displayPercentage}%` }}>
                </div>
            </div>
            <div className="progress-bar-texts">
                <span className="progress-bar-text">Customers</span>
                <span className="progress-bar-text">{displayPercentage}%</span>

            </div>
            <div className="progress-bar">
                <div className="progress-bar-fill" style={{ width: `${displayPercentage}%` }}>
                </div>
            </div>
            <div className="progress-bar-texts">
                <span className="progress-bar-text">Drafts</span>
                <span className="progress-bar-text">{displayPercentage}%</span>

            </div>
            <div className="progress-bar">
                <div className="progress-bar-fill" style={{ width: `${displayPercentage}%` }}>
                </div>
            </div>
            <div className="progress-bar-texts">
                <span className="progress-bar-text">Leads</span>
                <span className="progress-bar-text">{displayPercentage}%</span>

            </div>
            <div className="progress-bar">
                <div className="progress-bar-fill" style={{ width: `${displayPercentage}%` }}>
                </div>
            </div>
            <div className="progress-bar-texts">
                <span className="progress-bar-text">Leads</span>
                <span className="progress-bar-text">{displayPercentage}%</span>

            </div>
            <div className="progress-bar">
                <div className="progress-bar-fill" style={{ width: `${displayPercentage}%` }}>
                </div>
            </div>
            <div className="progress-bar-texts">
                <span className="progress-bar-text">Leads</span>
                <span className="progress-bar-text">{displayPercentage}%</span>

            </div>
            <div className="progress-bar">
                <div className="progress-bar-fill" style={{ width: `${displayPercentage}%` }}>
                </div>
            </div>
            <div className="progress-bar-texts">
                <span className="progress-bar-text">Leads</span>
                <span className="progress-bar-text">{displayPercentage}%</span>

            </div>
            <div className="progress-bar">
                <div className="progress-bar-fill" style={{ width: `${displayPercentage}%` }}>
                </div>
            </div>
            <div className="progress-bar-texts">
                <span className="progress-bar-text">Leads</span>
                <span className="progress-bar-text">{displayPercentage}%</span>

            </div>
            <div className="progress-bar">
                <div className="progress-bar-fill" style={{ width: `${displayPercentage}%` }}>
                </div>
            </div>
            
        </StyledProgressbar>

    );
};

const StyledProgressbar = styled.div`
    padding: 10px 10%;
    .progress-bar{
  width: 100%;
  background-color: #e0e0df;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 1.5vh;
}


.progress-bar-fill {
  height: 7px;
  background-color: #76c7c0;
  width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 1s ease-in-out;
  border-radius: 10px;
}

.progress-bar-text {
  color: black;
  font-weight: bold;
}

.progress-bar-texts{
    display: flex;
    justify-content: space-between;
}


`

export default Progressbar