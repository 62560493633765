import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const Forgot_password = () => {
    const [email, setEmail] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            // Send request to reset password
            const response = await axios.post("http://localhost:4000/forgot-password", {
                email: email
            });

            // Display success message to user
            alert(response.data.message);
        } catch (error) {
            console.error("Forgot password error:", error);
        }
    };

    return (
        <StyledForgotPassword>
            <div className="forgot-password-container">
                <h2>Forgot Password</h2>
                <form>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="reset-button" onClick={handleSubmit}>
                        Reset Password
                    </button>
                </form>
            </div>
        </StyledForgotPassword>
    );
};

const StyledForgotPassword = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;

    .forgot-password-container {
        background: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        width: 100%;
        max-width: 400px;
        box-sizing: border-box;
        text-align: center;
    }

    h2 {
        margin-bottom: 20px;
    }

    .form-group {
        margin-bottom: 15px;
        text-align: left;
    }

    .form-group label {
        display: block;
        margin-bottom: 5px;
    }

    .form-group input {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .reset-button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #8F0E16;
        color: white;
        font-size: 16px;
        cursor: pointer;
    }

    .reset-button:hover {
        background-color: #730A11;
    }
`;

export default Forgot_password;
