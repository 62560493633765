import * as XLSX from "xlsx";

export const handleFileUpload = (e, setExceldata) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);

       
        const modifiedData = parsedData.map((row) => {
            const modifiedRow = {};
            Object.keys(row).forEach((key) => {
                const newKey = key.toLowerCase().replace(/\s/g, "").replace(/[^a-z0-9]/g, "");
                modifiedRow[newKey] = row[key];
            });
            return modifiedRow;
        });

        setExceldata(modifiedData);
    };
};
