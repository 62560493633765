import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';



import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Buildingload } from '../api/Buildingload';
import { Adminload } from '../api/Adminload';

// imports for slices
import { setAdmin } from '../store/slices/AdminSlice';
import { setAdminId } from '../store/slices/AdminSlice';
import { setFederationlist } from '../store/slices/HousingFederationSlice';
import { setBuildinglist } from '../store/slices/BuildingSlice';
import { setSelectedbuildinglist } from '../store/slices/BuildingSlice';
import { setSelectedbuilding } from '../store/slices/BuildingSlice';
import { setLedgerlist } from '../store/slices/LedgerSlice';
import { ledgerload } from '../api/Ledgerload';
import { federationload } from '../api/federationload';



const secretKey = 'your-secret-key';
const apiUrl = process.env.REACT_APP_SERVERAPI;

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${apiUrl}login`, {
        username: username,
        password: password,
      });


      const token = response.data.token;


      if (token) {

        Cookies.set('token', token, { expires: 1 / 24 });
        const decodedToken = jwtDecode(token);

        const role = decodedToken.role;
        const userid = decodedToken.userid;




        const getadminresponse = await Adminload(`getadmins`, username);
        const federationresponse = await federationload('getfederations')
        dispatch((setFederationlist(federationresponse)))
        const buildingresponse = await Buildingload("getbuildings", getadminresponse[0].access, getadminresponse[0].role);
        const ledgerresponse = await ledgerload('getledgers');
        
   
        dispatch(setAdmin(role));
        dispatch(setAdminId(userid));
        dispatch(setBuildinglist(buildingresponse));
        dispatch(setSelectedbuildinglist(buildingresponse));
        dispatch(setSelectedbuilding(buildingresponse[0]));
        dispatch(setLedgerlist(ledgerresponse));


        alert(response.data.message);
        navigate('/Dashboard');
      }
    } catch (error) {
      console.error("Login error:", error);
    }

  };

  return (
    <StyledLogin>
      <div className="login-container">
        <div className="logo-container">
          <img src="./Resources/logo.png" alt="Logo" className="logo" />
        </div>
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input type="text" id="username" name="username"
              onChange={(e) => setUsername(e.target.value)}
              required />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password"
              onChange={(e) => setPassword(e.target.value)}
              required />
          </div>
          <button type="submit" className="login-button">Login</button>
        </form>
        <NavLink to={'/Forgotpass'}>
          <span className="forgot-password">Forgot Password?</span>
        </NavLink>
      </div>
    </StyledLogin>
  );
}

const StyledLogin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;

  .login-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    text-align: center;
  }

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .logo {
    max-width: 100px;
    height: auto;
  }

  h2 {
    margin-bottom: 20px;
  }

  .form-group {
    margin-bottom: 15px;
    text-align: left;
  }

  .form-group label {
    display: block;
    margin-bottom: 5px;
  }

  .form-group input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .login-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #8F0E16;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }

  .login-button:hover {
    background-color: #730A11 ;
  }

  .forgot-password {
    display: block;
    text-align: center;
    margin-top: 15px;
    color:  #730A11;
    text-decoration: none;
  }

  .forgot-password:hover {
    text-decoration: underline;
  }
`;

export default Login;
