import { encryptData , decryptData } from "../Functions/Data_protection";
import { secretKey } from "../constant";
import { apiUrl } from "../constant";
import axios from "axios";

export const handleAdd = async (payload, url , reference) => {
    const encryptedPayload = encryptData(payload, secretKey);
   
    try {
        const response = await axios.post(`${apiUrl}${url}`, { payload: encryptedPayload, reference : reference});
        const decryptedResponse = decryptData(response.data.payload, secretKey);
        console.log(decryptedResponse)



        return decryptedResponse;

    } catch (error) {
        console.log(error);
    }
}


