import axios from "axios";
export const RetrieveDemographicData = async (pincode) => {
    try {
        const demographicResponse = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`);
        const responseData = demographicResponse.data;
        if (responseData.length > 0) {
            return responseData;
        } else {
            console.log("No data found for the given pincode.");
        }
    } catch (error) {
        console.error("Error retrieving demographic data:", error);
    }
};