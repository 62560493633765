import React, { useState, useEffect, useRef } from 'react';

import Sidebar from '../Sidebar';
import styled from 'styled-components';
import Header from '../Components/Mainpage_componnets/Header';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faArrowRight, faFileUpload, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleExportExcel } from '../Functions/Excelprint';
import { RetrieveDemographicData } from '../Functions/RetrieveDemographic';
import { HandleDelete } from '../api/Deletion';
import { validateForm } from '../Functions/Form_validation';
import { handleAdd } from '../api/Addition';
import { handleFileUpload } from '../Functions/Excelupload';
import { handlefilter } from '../Functions/FilterRecords';
import { useDispatch, useSelector } from 'react-redux';
import { setFederationlist } from '../store/slices/HousingFederationSlice';
import { addFederation } from '../store/slices/HousingFederationSlice';
import { updateFederation } from '../store/slices/HousingFederationSlice';
import { removeFederation } from '../store/slices/HousingFederationSlice';



const Housing_federation = () => {

    const federationlistState = useSelector(state => state.federation.federationlist);

    const adminidState = useSelector(state => state.admin.userid);

    const dispatch = useDispatch();



    // const selectedfederationlistState = useSelector(state => state.federation.selectedfederationlist);




    const [originalRecords, setOriginalRecords] = useState([]);
    const [records, setRecords] = useState([]);
    const [slideropen, setSlideropen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [geographicdata, setGeographicdata] = useState([]);
    const [fedname, setFedname] = useState('');
    const [regid, setRegid] = useState('');
    const [address, setAddress] = useState('');
    const [pocname, setPocname] = useState('');
    const [pocnumber, setPocnumber] = useState('');
    const [pocwhatsapp, setWhatsapp] = useState('');
    const [bankname, setBankname] = useState('');
    const [accountnumber, setAccountnumber] = useState('');
    const [bankifsc, setBankifsc] = useState('');
    const [fedemail, setFedemail] = useState('');
    const [status, setStatus] = useState(true);
    const [hsgfedid, setHsgfedid] = useState('');
    const [block, setBlock] = useState('');
    const [State, setState] = useState('');
    const [district, setDistrict] = useState('');
    const [division, setDivision] = useState('');
    const [region, setRegion] = useState('');
    const [pincode, setPincode] = useState('');
    const [area, setArea] = useState('');
    const [errors, setErrors] = useState({});
    const [exceldata, setExceldata] = useState([]);
    const [addexcelfilebox, setAddexcelbox] = useState(false);





    // States for handling the message box and related things
    const [smsboxexpanded, setSmsboxexpanded] = useState(true);
    const [emailboxexpanded, setEmailboxexpanded] = useState(false);
    const [whatsappboxexpanded, setWhatappboxexpanded] = useState(false);
    const [smsMessage, setSmsMessage] = useState('');
    const [whatsappMessage, setWhatsappMessage] = useState('');
    const [emailMessage, setEmailMessage] = useState('');

    const [addbtnclicked, setAddbtnclicked] = useState(false);

    // State to store the payload of message 
    const [messageobject, setMessageobject] = useState([]);



    // States to handle message related things
    const [messageboxexpand, setMessageboxexpand] = useState(false);
    
    const [selectedRows, setSelectedRows] = useState([]);






    const [updateclicked, setUpdateclicked] = useState(false);
    const [Deleteclicked, setDeleteclicked] = useState(false);

    // const tableRef = useRef();

    // const handlePrint = useReactToPrint({
    //     content: () => tableRef.current,
    // });



    const pincodeRef = useRef(null);

    const openslider = () => {
        setSlideropen(true);
    };

    const columns = [
        {
            name: '',
            selector: row => (
                <div onClick={() => {
                    setSlideropen(false);
                    setMessageboxexpand(false);
                    setAddexcelbox(false);
                    setDeleteclicked(true);
                    setSelectedRow(row);
                    setHsgfedid(row.hsgfedid);
                }}>
                    <button style={{
                        border: 'none',
                        background: 'none',
                        cursor: 'pointer'
                    }}>
                        <FontAwesomeIcon icon={faClose} style={{ color: '#8E3B3E', fontSize: '1.5rem' }} />
                    </button>
                </div>
            ),
            sortable: false, // This column does not need to be sortable
            width: '50px',
            wrap: true
        },


        { name: 'Name', selector: row => <div onClick={() => { Updateclicked(row) }}>{row.fedname}</div>, sortable: true, width: '150px', wrap: true },
        { name: 'Address', selector: row => row.address, sortable: true, wrap: true, width: '250px' },
        { name: 'POC Name', selector: row => row.pocname, sortable: true, width: '150px', wrap: true },
        { name: 'POC Number', selector: row => row.pocnumber, sortable: true, width: '150px', wrap: true },
        { name: 'POC WhatsApp', selector: row => row.pocwhatsapp, sortable: true, width: '150px', wrap: true },

    ];


    const handleLoad = async () => {
        try {

            setOriginalRecords(federationlistState);
            setRecords(federationlistState);



        } catch (error) {
            console.log(error);
        }



    };

    const resetControls = () => {
        handleLoad();
        setFedname('');
        setRegid('');
        setAddress('');
        setPocname('');
        setPocnumber('');
        setWhatsapp('');
        setBankname('');
        setAccountnumber('');
        setBankifsc('');
        setFedemail('');
        setHsgfedid('');
        setStatus('');
        setBlock('');
        setState('');
        setDistrict('');
        setDivision('');
        setRegion('');
        setPincode('');
        setArea('');


    };



    const Handlepincodesearch = async () => {
        const pincodeValue = pincodeRef.current.value;
        console.log(pincodeValue);
        const demographicData = await RetrieveDemographicData(pincodeValue);
        if (demographicData) {
            setGeographicdata(demographicData);
            HandleDefaultAssignment(demographicData);


        };

    };





    useEffect(() => {
        handleLoad();
    }, [federationlistState]);





    const Updateclicked = (row) => {
        setSelectedRow(row); // Storing the selected row data
        setDeleteclicked(false);
        setMessageboxexpand(false)
        setSlideropen(true);
        setUpdateclicked(true);
        setHsgfedid(row.hsgfedid);


        // populate data to input
        setFedname(row.fedname);
        setRegid(row.regid);
        setAddress(row.address);
        setPocname(row.pocname);
        setPocnumber(row.pocnumber);
        setWhatsapp(row.pocwhatsapp);
        setBankname(row.bankname);
        setAccountnumber(row.accountnumber);
        setBankifsc(row.bankifsc);
        setFedemail(row.fedemail);
        setStatus(row.status);
        setBlock(row.block);
        setState(row.State);
        setDistrict(row.district);
        setRegion(row.region);
        setDivision(row.division);
        setPincode(row.pincode);
        setArea(row.area);


    };

    const HandleDefaultAssignment = (demographicData) => {
        if (!area) {
            const defaultgeographicdata = demographicData[0].PostOffice[0];
            if (defaultgeographicdata) {
                // Update state variables based on selected area data
                setBlock(defaultgeographicdata.Block);
                setRegion(defaultgeographicdata.Region);
                setDistrict(defaultgeographicdata.District);
                setDivision(defaultgeographicdata.Division);
                setState(defaultgeographicdata.State);
                setArea(defaultgeographicdata.Name)
            }

        }
    }





    const handleSubmit = async (e) => {
        e.preventDefault();
        let payload;

        // Check if exceldata has more than one row or not
        if (exceldata.length > 1) {

            payload = exceldata.map(data => ({
                fedname: data.fedname,
                regid: data.regid,
                address: data.address,
                pocname: data.pocname,
                pocnumber: data.pocnumber,
                pocwhatsapp: data.pocwhatsapp,
                bankname: data.bankname,
                accountnumber: data.accountnumber,
                bankifsc: data.bankifsc,
                fedemail: data.fedemail,
                // status: data.status,
                block: data.block,
                State: data.State,
                district: data.district,
                division: data.division,
                region: data.region,
                area: data.area,
                pincode: data.pincode,
                createdby: adminidState,
            }));
        }
        else {

            const data = exceldata[0] || {};
            payload = {
                hsgfedid: hsgfedid,
                fedname: data.fedname || fedname,
                regid: data.regid || regid,
                address: data.address || address,
                pocname: data.pocname || pocname,
                pocnumber: data.pocnumber || pocnumber,
                pocwhatsapp: data.pocwhatsapp || pocwhatsapp,
                bankname: data.bankname || bankname,
                accountnumber: data.accountnumber || accountnumber,
                bankifsc: data.bankifsc || bankifsc,
                fedemail: data.fedemail || fedemail,
                block: data.block || block,
                State: data.State || State,
                district: data.district || district,
                division: data.division || division,
                region: data.region || region,
                area: data.area || area,
                pincode: data.pincode || pincode,
                createdby: updateclicked ? "" : adminidState,
                modifiedby: updateclicked ? adminidState : "",

            };

        }

        console.log(`This is the payload given:`, payload);


        // let validationErrors = {};
        // if (Array.isArray(payload)) {
        //     validationErrors = payload.map(validateForm).flat();
        // } else {
        //     validationErrors = validateForm(payload);
        // }


        // if (Object.keys(validationErrors).length > 0) {
        //     console.log(validationErrors);
        //     setErrors(validationErrors);
        //     return;
        // }

        setErrors({});

        try {
            const response = await handleAdd(payload, 'addplusupdatefederation');
            if (response[0].status) {
                toast.success(`${response[0].message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setSlideropen(false);
                setAddexcelbox(false)
                if (response[0].update) {
                    dispatch(updateFederation(response[0].data));

                }
                else {
                    response.forEach(item => {
                        dispatch(addFederation(item.data));
                    });


                }




            }
        } catch (error) {
            console.error(error);
        }
        finally {
            payload = null;
            setExceldata([]);

        }
    };
    const handleRowSelected = state => {
        const selectedRows = state.selectedRows;
        setSelectedRows(selectedRows);
        setMessageobject(selectedRows);


    };

    const handleSendEmail = () => {
        const messagepayload = messageobject.map(row => ({
            fedemail: row.fedemail,
            fedname: row.fedname,
            pocname: row.pocname,
            pocnumber: row.pocnumber,
            pocwhatsapp: row.pocwhatsapp,
            sms: smsMessage,
            whatsapp: whatsappMessage,
            emailmsg: emailMessage

        }))

        console.log(messagepayload);
    };

    const HandleDeleteclicked = async () => {
        const deletedresponsedata = await HandleDelete(hsgfedid, "deletefederation");
        if (deletedresponsedata.status) {
            toast.success(deletedresponsedata.message)
            setDeleteclicked(false);
            dispatch(removeFederation({ hsgfedid: deletedresponsedata.data }));
        }
    };




    return (
        <StyledHousingfed slideropen={slideropen}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="container">
                <div className="template-container">
                    <a href="./Templates/federation_template.xlsx">
                        <button id='template-btn'>Download Template</button>
                    </a>
                </div>
                <div className="sub-container">
                    <header className='header'>
                        <span id='heading'>Federation List</span>
                        <div className="work-buttons">

                            <FontAwesomeIcon id='download-btn' icon={faFileDownload} onClick={() => handleExportExcel(selectedRows)} />

                            <FontAwesomeIcon id='upload-excel-btn' icon={faFileUpload} onClick={() => { setAddexcelbox(true); setAddbtnclicked(false) }} />


                            {/* <FontAwesomeIcon id='download-btn' icon={faFilePdf} onClick={handlePrint} /> */}

                            <input type="text" placeholder='search' id='search' onChange={(event) => { handlefilter(event, originalRecords, setRecords, "fedname") }} />
                            <button id='send-mail-btn' onClick={() => {
                                if (messageobject.length >= 1) {
                                    setSlideropen(false);
                                    setDeleteclicked(false);
                                    setMessageboxexpand(true)
                                }
                                else {
                                    toast.error("Select row first");
                                }

                            }}>Send Message</button>

                            <button id='add-btn' onClick={() => {
                                setMessageboxexpand(false);
                                setAddexcelbox(false);
                                setDeleteclicked(false);
                                openslider();
                                setUpdateclicked(false); setMessageboxexpand(false); resetControls();

                            }}>Add New

                            </button>



                        </div>
                    </header>
                    <div id="table-component">
                        <DataTable
                            // ref={tableRef}
                            columns={columns}
                            data={records}
                            selectableRows
                            onSelectedRowsChange={handleRowSelected}
                            pagination
                            paginationPerPage={5}
                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                            className='data-table'
                        ></DataTable>
                    </div>


                </div>
                {addexcelfilebox && (
                    <div id="overlayaddexcelbox">
                        <FontAwesomeIcon
                            icon={faClose}
                            id="emailmessageicon"
                            onClick={() => { setAddexcelbox(false); }}
                        />

                        <span>Upload Excel</span>
                        <input style={{ display: 'block', textAlign: 'center', border: '1px solid #8E3B3E' }} type="file" name="" id="" onChange={(e) => { handleFileUpload(e, setExceldata) }} />
                        <button id='excelbox-submit-btn' onClick={handleSubmit}>import</button>

                    </div>

                )}

                {Deleteclicked && (
                    <div className="overlaydltbox">
                        <p>Are u sure want to Delete ?</p>
                        <div className="dltresponseboxes">
                            <button id='dlt-btns' onClick={HandleDeleteclicked} >Delete</button>
                            <button id='dlt-btns' onClick={() => setDeleteclicked(false)} >Cancel</button>
                        </div>
                    </div>
                )}

                {messageboxexpand && (
                    <div className="overlaymessagebox">
                        <FontAwesomeIcon
                            icon={faClose}
                            id="emailmessageicon"
                            onClick={() => { setMessageboxexpand(false); }}
                        />

                        <h2 style={{ color: '#8E3B3E' }}>Send Message</h2>
                        <div className="msg-checkboxes">
                            <label htmlFor="smsboxexpanded">Send SMS</label>
                            <input
                                type="checkbox"
                                id="sendSMS"
                                checked={smsboxexpanded}
                                onChange={() => setSmsboxexpanded(!smsboxexpanded)}
                            />
                            <label htmlFor="sendEmail">Send Email</label>
                            <input
                                type="checkbox"
                                id="sendEmail"
                                checked={emailboxexpanded}
                                onChange={() => setEmailboxexpanded(!emailboxexpanded)}
                            />
                            <label htmlFor="sendWhatsapp">Send Whatsapp</label>
                            <input
                                type="checkbox"
                                id="sendWhatsapp"
                                checked={whatsappboxexpanded}
                                onChange={() => setWhatappboxexpanded(!whatsappboxexpanded)}
                            />
                        </div>
                        {smsboxexpanded && (
                            <textarea
                                rows={5}
                                placeholder="Enter SMS"
                                className="overlay-message-input"
                                value={smsMessage}
                                onChange={(e) => setSmsMessage(e.target.value)}
                            ></textarea>
                        )}
                        {whatsappboxexpanded && (
                            <textarea
                                rows={5}
                                placeholder="Enter Whatsapp Message"
                                className="overlay-message-input"
                                value={whatsappMessage}
                                onChange={(e) => setWhatsappMessage(e.target.value)}
                            ></textarea>
                        )}
                        {emailboxexpanded && (
                            <textarea
                                rows={5}
                                placeholder="Enter Email Message"
                                className="overlay-message-input"
                                value={emailMessage}
                                onChange={(e) => setEmailMessage(e.target.value)}
                            ></textarea>
                        )}
                        <button
                            className="overlay-email-submit"
                            onClick={handleSendEmail}
                        >
                            Submit
                        </button>
                    </div>
                )}

            </div>
            <div className="slider">
                <div className="icon">
                    <FontAwesomeIcon icon={faClose} id='closeicon' onClick={() => setSlideropen(false)} />
                    <p style={{ marginLeft: '2%' }}>Housing Federation</p>
                </div>
                <form className="slider-form" onSubmit={handleSubmit}>

                    <label htmlFor="Name">Name:</label>
                    <input type="text" id="Name" name="Name" placeholder="Enter Name" value={fedname} onChange={(e) => setFedname(e.target.value)} />
                    {/* {errors.fedname && <p style={{ color: 'red' }}>{errors.fedname}</p>} */}

                    <label htmlFor="Registration Id">Registration Id:</label>
                    <input type="text" id="Registration Id" name="Registration Id" placeholder="Enter Registration Id" value={regid} onChange={(e) => setRegid(e.target.value)} />
                    {/* {errors.regid && <p style={{ color: 'red' }}>{errors.regid}</p>} */}

                    <label htmlFor="Address">Address</label>
                    <input type="text" id="Address" name="ADRESS" placeholder="Enter Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                    {/* {errors.address && <p style={{ color: 'red' }}>{errors.address}</p>} */}

                    <label htmlFor="Address">Pincode:</label>


                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <input ref={pincodeRef} style={{ width: '88%' }} type="text" id="pincode" name="PIN CODE" placeholder="Enter Pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} />

                        <button type='button' style={{ width: '10%', height: '3.5vh' }} onClick={Handlepincodesearch}>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </button>


                    </div>
                    {/* {errors.pincode && <p style={{ color: 'red' }}>{errors.pincode}</p>} */}



                    <label htmlFor="Address">Area</label>


                    <select
                        style={{ width: '100%', height: '3.5vh', marginBottom: '15px' }}
                        name="AREA"
                        id="area_dropdown"
                        onChange={(e) => {
                            const selectedArea = e.target.value;
                            setArea(selectedArea);


                            const selectedAreaData = geographicdata[0].PostOffice.find(item => item.Name === selectedArea);

                            console.log(selectedAreaData);
                            if (selectedAreaData) {
                                // Update state variables based on selected area data
                                setBlock(selectedAreaData.Block);
                                setRegion(selectedAreaData.Region);
                                setDistrict(selectedAreaData.District);
                                setDivision(selectedAreaData.Division);
                                setState(selectedAreaData.State);
                            }
                        }}
                    >
                        {geographicdata.length > 0 &&
                            geographicdata[0].PostOffice.map((item, index) => (
                                <option key={index} value={item.Name}>
                                    {item.Name}
                                </option>
                            ))}
                    </select>
                    {/* {errors.area && <p style={{ color: 'red' }}>{errors.area}</p>} */}




                    <label htmlFor="Address">Block</label>

                    <input type="text" id="block" name="BLOCK" placeholder="Enter Block" value={block} />

                    <label htmlFor="Address">District</label>

                    <input type="text" id="district" name="DISTRICT" placeholder="Enter District" value={district} />

                    <label htmlFor="Address">Division</label>

                    <input type="text" id="division" name="DIVISION" placeholder="Enter Division" value={division} />

                    <label htmlFor="Address">Region</label>

                    <input type="text" id="region" name="REGION" placeholder="Enter Region" value={region} />

                    <label htmlFor="Address">State:</label>

                    <input type="text" id="state" name="STATE" placeholder="Enter State" value={State} />


                    <label htmlFor="POC Name">POC Name:</label>
                    <input type="text" id="POC Name" name="POC Name" placeholder="Enter POC Name" value={pocname} onChange={(e) => setPocname(e.target.value)} />
                    {/* {errors.pocname && <p style={{ color: 'red' }}>{errors.pocname}</p>} */}


                    <label htmlFor="POC Number">POC Number:</label>
                    <input type="text" id="POC Number" name="POC Number" placeholder="Enter POC Number" value={pocnumber}
                        onChange={(e) => setPocnumber(e.target.value)} />
                    {/* {errors.pocnumber && <p style={{ color: 'red' }}>{errors.pocnumber}</p>} */}

                    <label htmlFor="POC Whatsapp Number">POC Whatsapp Number:</label>
                    <input type="text" id="POC Whatsapp Number" name="POC Whatsapp Number" placeholder="Enter POC Whatsapp Number" value={pocwhatsapp} onChange={(e) => setWhatsapp(e.target.value)} />
                    {/* {errors.pocwhatsapp && <p style={{ color: 'red' }}>{errors.pocwhatsapp}</p>} */}

                    <label htmlFor="Email Id">Email Id:</label>
                    <input type="text" id="Email Id" name="Email Id" placeholder="Enter Email" value={fedemail} onChange={(e) => setFedemail(e.target.value)} />
                    {/* {errors.fedemail && <p style={{ color: 'red' }}>{errors.fedemail}</p>} */}

                    <label htmlFor="Bank Name">Bank Name:</label>
                    <input type="text" id="Bank Name" name="Bank Name" placeholder="Enter Bank Name" value={bankname} onChange={(e) => setBankname(e.target.value)} />
                    {/* {errors.bankname && <p style={{ color: 'red' }}>{errors.bankname}</p>} */}

                    <label htmlFor="Bank Account Number">Bank Account Number:</label>
                    <input type="text" id="Bank Account Number" name="Bank Account Number" placeholder="Enter Bank Account Number" value={accountnumber} onChange={(e) => setAccountnumber(e.target.value)} />



                    <label htmlFor="Bank IFSC">Bank IFSC:</label>
                    <input type="text" id="Bank IFSC" name="Bank IFSC" placeholder="Enter IFSC Code" value={bankifsc} onChange={(e) => setBankifsc(e.target.value)} />
                    {/* {errors.bankifsc && <p style={{ color: 'red' }}>{errors.bankifsc}</p>} */}




                    {updateclicked ? (
                        <button type="submit">Update</button>
                    ) : (
                        <button type="submit">Add</button>
                    )}

                </form>
            </div>
            <Header />
            <Sidebar />
        </StyledHousingfed>
    );
}
const StyledHousingfed = styled.div`

.container{
    position: relative;
    left: 15%;
    width: 85%;
    min-height: 100vh;
    padding: 20vh 10%;
   


}

.template-container{
    margin-left: 85%;
    margin-bottom: 2vh;
    
}

#template-btn{
    width: 90%;
    height: 4vh;
    background-color: #8E3B3E;
    border: none;
    color: white;
    border-radius: 10px;

}

.sub-container{
    width: 100%;
    background-color: white;
    padding: 4vh 5%;
    box-shadow: 0px 4px 8px 0px #E2CACB; 


}

.header{
    display: flex;
    justify-content: space-between;
}

.work-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

   
}

#search{
    padding: 2px 10px;
    height: 4vh;
    border-radius: 10px;
    border: 2px solid #8E3B3E;

}

#search:focus{
    border: 2px solid #8E3B3E;
    outline: none;

}



#add-btn{
    margin-left: 1vw;
    text-align: center;
    height: 4vh;
    border-radius: 10px;
    width: 8vw;
    background-color: #8E3B3E;
    color: #fff;
    border: none;


}

#add-dropbox{
    right: -2%;
    margin-top: 6vh;
    position: absolute;
    width: 8vw;
    border-radius: 10px;
    z-index: 1;
    background-color: yellow;
    background-color: #8E3B3E;
    border: 1px solid #fff;


}

.add-dropbox-btns{
    width: 100%;
    background-color: #8E3B3E;
    border: none;
    border-radius: 10px;
    min-height: 3vh;

    color: #fff;
}


#download-btn{
    margin-right: 1vw;
    border-radius: 10px;
    color: #8E3B3E;
    border: none;
    font-size: 1.8rem;
}

#upload-excel-btn{
    margin-right: 1vw;
    border-radius: 10px;
    color: #8E3B3E;
    border: none;
    font-size: 1.8rem;

}

#send-mail-btn{
    margin-left: 1vw;
    text-align: center;
    height: 4vh;
    border-radius: 10px;
    width: 8vw;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
}

#heading{
    font-size: 2rem;
}



.slider{
    width: 20%;
    height: 100vh;
    position: absolute;
    right: 0;
    display: ${props => (props.slideropen ? 'block' : 'none')};
    transition: transform 0.3s ease-in-out;
    top: 0;
    background-color: #FDD5D6;
    transition: right 0.3s ease;
    z-index: 1;
    overflow-y: scroll;
    padding: 0px 1%;
}

.icon{
    display: flex;
    align-items: center;
    position: relative;
    top: 2vh;
    left: 5%;
    font-size: 1.2rem;

}

    #closeicon{
        
        font-size: 2rem;
        color: #8E3B3E;

    }

    
.slider-form {
    max-width: 400px;
    margin: 5vh auto 0 auto;
}

.slider-form label {
    display: block;
    margin-bottom: 5px;
}

.slider-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.slider-form button {
    width: 100%;
    padding: 10px;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.overlaydltbox{
    position: absolute;
    top: 40vh;
    left: 30%;
    z-index: 2;
    width: 40%;
    height: 20vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 2rem;
    display: grid;
    place-items: center;
}

#overlayaddexcelbox{
    position: absolute;
    top: 30vh;
    left: 40%;
    z-index: 2;
    width: 20%;
    height: 15vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 2rem;
    display: grid;
    place-items: center;
    
}

.dltresponseboxes{
    display: flex;
    justify-content: space-evenly;
    width: 80%;


}

#excelbox-submit-btn{
    width: 40%;
    height: 3vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
    border-radius: 10px;
}

#dlt-btns{
    width: 25%;
    height: 4vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
}

.overlaymessagebox{
    position: absolute;
    top: 40vh;
    left: 35%;
    z-index: 2;
    width: 30%;
    padding: 2vh 0px;
    min-height: 30vh;
    background-color: #FDD5D6;
    display: grid;
    place-items: center;
}

.overlay-message-input{
    width: 80%;
    padding: 2px;
    margin-bottom: 2vh;
    
}

.overlay-email-submit{
    width: 80%;
    height: 4vh;
    background-color: #8E3B3E;
    border: none;
    color: #fff;


}

#emailmessageicon{
    position: absolute;
    top: 1vh;
    left: 2%;
    font-size: 1.8rem;
    color: #8E3B3E;

}

.msg-checkboxes{
    width: 80%;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vh;
}



@media screen and (max-width: 480px) {
        
    .container{
    margin-top: 5vh;
    position: relative;
    left: 0;
    width: 100%;
    min-height: 100vh;
    padding: 5vh 5%;
    overflow-x: hidden;

    }

    
    .work-buttons{
        font-size: 0.7rem;
        justify-content: flex-end;
    }


    #search{
    width: 35%;
    padding: 2px 10px;
    height: 4vh;
    border: 2px solid #8E3B3E;

    }

    #add-btn{
    text-align: center;
    height: 4vh;
    width: 22.5%;
    font-size: 0.7rem;

    
    
    
    }

    #send-mail-btn{
    margin-left: 1vw;
    height: 4vh;
    width: 22.5%;
    font-size: 0.7rem;
    
    
    }

    #heading{
        font-size: 1rem;
    }

    .slider{
        width: 100%;
        z-index: 1;
    }

    .overlaydltbox{
    position: absolute;
    top: 30vh;
    left: 20%;
    z-index: 2;
    width: 60%;
    height: 10vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 1rem;
    display: grid;
    place-items: center;
}



.dltresponseboxes{
    display: flex;
    justify-content: space-evenly;
    width: 80%;


}

#dlt-btns{
    width: 25%;
    height: 3vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
}



}


@media screen and (min-width: 768px) and (max-width: 1020px) 
{
    .container{
        position: relative;
        left: 20%;
        width: 80%;
        min-height: 100vh;
        padding: 20vh 5%;
    


    }



    #heading{
        font-size: 1.5rem;
    }

    .slider{
        width: 40%;
        z-index: 1;
    }

    .overlaydltbox{
    position: absolute;
    top: 40vh;
    left: 25%;
    z-index: 2;
    width: 50%;
    height: 20vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 1.5rem;
    display: grid;
    place-items: center;    

    }

    .dltresponseboxes{
    display: flex;
    justify-content: space-evenly;
    width: 80%;


    }

    #dlt-btns{
    width: 25%;
    height: 4vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
    }

    #search{
    height: 6vh;
    border: 2px solid #8E3B3E;

    }

    #add-btn{
    height: 6vh;
    font-size: 0.8rem;



    

    }

    #send-mail-btn{
    height: 6vh;
    font-size: 0.8rem;
    width: 30%;

    
    
    }


}

`


export default Housing_federation


