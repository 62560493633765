import React from 'react'
import styled from 'styled-components'
import { useState } from 'react'

import Header from './Components/Mainpage_componnets/Header'
import Dashboard from './Components/Mainpage_componnets/Dashboard'
import Sidebar from './Sidebar'




const Mainpage = () => {


  return (
    <StyledMain>
      <Dashboard />
      <Header />
      <Sidebar />



    </StyledMain>
  )
}

const StyledMain = styled.div`
position: relative;
left: 15%;
width: 85%;
min-height: 100vh;
background-color: #F9FAFC;

@media screen and (max-width: 480px){
    left: 0;
    width: 100%;
}


`

export default Mainpage