import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    voucherlist: [],
};

const VoucherSlice = createSlice({
    name: 'voucher',
    initialState,
    reducers: {
        setVoucherlist: (state, action) => {
            state.voucherlist = action.payload;
        },

        addVoucher: (state, action) => {
            state.voucherlist.push(action.payload);
        },

        updateVoucher: (state, action) => {
            const index = state.voucherlist.findIndex(voucher => voucher.voucherid === action.payload.voucherid);
            if (index !== -1) {
                state.voucherlist[index] = action.payload;
            }
        },

        removeVoucher: (state, action) => {
            return {
                ...state,
                voucherlist: state.voucherlist.filter(voucher => voucher.voucherid !== action.payload.voucherid)
            };
        }
    }
});

// Export the action creators
export const { setVoucherlist, addVoucher, updateVoucher, removeVoucher } = VoucherSlice.actions;

// Export the reducer
export default VoucherSlice.reducer;
