import axios from "axios";
import { decryptData } from "../Functions/Data_protection";
import { secretKey , apiUrl} from "../constant";


export const Bill_head_load = async (uri , reference ) => {

    const response = await axios.get(`${apiUrl}${uri}`,{
        params : {reference}
    });
    const decryptedData = decryptData(response.data.payload, secretKey);
   
    return decryptedData


    

}

