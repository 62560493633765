import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { handleFileUpload } from '../Functions/Excelupload';

import Header from '../Components/Mainpage_componnets/Header';
import Sidebar from '../Sidebar';


import 'jspdf-autotable';
import { SaveUploadedExcel } from '../Functions/SaveUploadedExcel';
import { handleExportExcel } from '../Functions/Excelprint';
import { handlefilter } from '../Functions/FilterRecords';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';

import { billload } from '../api/billload';
import axios from "axios";
import { apiUrl } from '../constant';


import { handleAdd } from '../api/Addition';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faFileUpload, faFileDownload, faEdit } from '@fortawesome/free-solid-svg-icons';

import { decryptData } from '../Functions/Data_protection';

import { secretKey } from '../constant';



const Bill_generation = () => {

    const dispatch = useDispatch();
    const buildinglistState = useSelector(state => state.building.buildinglist);

    const [exceldata, setExceldata] = useState([]);
    const [columns, setColumns] = useState([]);
    const [excelFile, setExcelFile] = useState(null);
    const [billdata, setBilldata] = useState([]);
    const [displaybilldata, setDisplaybilldata] = useState([]);
    const [billheadlist, setBillheadlist] = useState([]);
    const [billlist, setBilllist] = useState([]);
    const [filteredledger, setFilteredledger] = useState([]);




    const [duedate, setDuedate] = useState('');
    const [billdate, setBilldate] = useState('');
    const [billmonth, setBillmonth] = useState('');

    const [selectedRows, setSelectedRows] = useState(null);
    const [selectedRow, setSelectedRow] = useState({});

    const [originalRecords, setOriginalRecords] = useState([]);
    const [updateclicked, setUpdateclicked] = useState(false);

    const [addexcelfilebox, setAddexcelbox] = useState(false);
    const [slideropen, setSlideropen] = useState(false);



    const [formData, setFormData] = useState({});

    const excludeKeys = new Set(['billguid', '', 'ownername', 'flatshopno', 'area', 'duedate', 'billdate', 'ledid', 'bldgid', 'twowheeler', 'fourwheeler', 'subtotal', 'netpayable', 'updatedat', 'createdat', 'billno', '__v', 'vouchers', 'period', 'wingno', '_id']);

    const [billcalculationdone , setBillCalculationDone] = useState(false);



    const selectedbuildinglistState = useSelector(state => state.building.selectedbuildinglist);

    const selectedbuildingState = useSelector(state => state.building.selectedbuilding);


    const ledgerlistState = useSelector(state => state.ledger.ledgerlist);

    const billheadlistState = useSelector(state => state.billhead.billheadlist);

    const voucherlistState = useSelector(state => state.voucher.voucherlist);


    const [localselectedbuildingid, setSelectedbuildingid] = useState('');
    const [localselectedbuildingdata, setSelectedbuildingdata] = useState([]);

    const CapitalizeKeys = (bills) => {
        let tempBills = bills.map(item => {
            let tempBill = {};
            Object.keys(item).forEach(key => {
                let capitalizedKey = key.toUpperCase();
                tempBill[capitalizedKey] = item[key];
            });
            return tempBill;
        });
        return tempBills;
    };

    const handleload = async () => {
        if (selectedbuildingState) {
            const billResponse = await billload("getbills", selectedbuildingState.apprevname);
            setBilllist(billResponse);

            const bills = await CapitalizeKeys(billResponse);

            const startingKeys = [
                "BILLGUID",
                "OWNERNAME",
                "FLATSHOPNO",
                "AREA",
                "BILLDATE",
                "PERIOD",
                "DUEDATE",
                "WINGNO",
                "BUILDINGNO",
                "TWOWHEELER",
                "FOURWHEELER"
            ];

            const endingKeys = [
                "SUBTOTAL",
                "PRINCIPAL",
                "INTEREST",
                "PREVIOUSMONTHDUE",
                "INTERESTFORPREVIOUSMONTHOS",
                "ADJUSTMENTS",
                "NETPAYABLE",
                "SUMOFINTEREST",
                "PAYMENT",
                "INTERESTCALC",
                "PRINCIPALCARRIED"
            ];

            const sortedBills = bills.map(bill => {
                // Delete unwanted keys
                delete bill._ID;
                delete bill.LEDID;
                delete bill.BLDGID;
                delete bill.VOUCHERS;
                delete bill.CREATEDAT;
                delete bill.UPDATEDAT;
                delete bill.__V;
                delete bill.BILLNO;

                const sortedData = {};

                // Add known starting fields
                startingKeys.forEach(key => {
                    if (bill[key] !== undefined) {
                        sortedData[key] = bill[key];
                    }
                });

                // Add remaining unknown fields
                Object.keys(bill).forEach(key => {
                    if (!startingKeys.includes(key) && !endingKeys.includes(key)) {
                        sortedData[key] = bill[key];
                    }
                });

                // Add known ending fields
                endingKeys.forEach(key => {
                    if (bill[key] !== undefined) {
                        sortedData[key] = bill[key];
                    }
                });

                return sortedData;
            });

            console.log(sortedBills);

            setDisplaybilldata(sortedBills);
            const columns = await createColumns(sortedBills[0]);
            setColumns(columns);
        }

    };




    // Creating dynamic columns just to avoid undefined heads
    const createColumns = (parambills) => {
        if (!parambills) return [];

        const columns = Object.keys(parambills).map(key => {
            return {
                name: key.toUpperCase(),
                selector: row => row[key],
                sortable: true,
                wrap: true,
                width: '150px'
            };
        });
        columns.unshift({
            name: '',
            selector: row => (
                <div onClick={() => { Updateclickedfunc(row) }}>
                    <button style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faEdit} style={{ color: '#8E3B3E', fontSize: '1.2rem' }} />
                    </button>
                </div>
            ),
            sortable: false,
            width: '50px',
            wrap: true
        });

        setColumns(columns);
        return columns;
    };









    const normalizeKeys = (bills) => {
        const newbillsarray = [];
        bills.forEach((elem) => {
            const newBill = {};
            Object.keys(elem).forEach((key) => {
                const normalizedKey = key.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
                newBill[normalizedKey] = elem[key];
            });
            newbillsarray.push(newBill)
        });
        return newbillsarray
    }



    // Check
    const Processexceldata = () => {

        setColumns(createColumns(exceldata[0]));
        setDisplaybilldata(exceldata);

        setBilldata(normalizeKeys(exceldata));
    };



    useEffect(() => {
        handleload();
    }, [])




    useEffect(() => {
        handleload();
    }, [selectedbuildingState]);


    const calculateBillHeads = async () => {
        
        if (!selectedbuildingState) {            
            toast.error("Select a Building First");
        } else {
            if (!billdate || !duedate || !billmonth) {
                toast.error("Ensure Billdate , Duedate and Billmonth are Filled ")
            }
            else {
                const bldgid = selectedbuildingState.bldgid;
                const apprevname = selectedbuildingState.apprevname;
                const response = await axios.get(`${apiUrl}calculateBillHeads`, {
                    params: { bldgid: bldgid, apprevname: apprevname, billmonth: billmonth, duedate: duedate, billdate: billdate }
                });
                const decrypteddata = await decryptData(response.data.payload, secretKey);

                console.log(decrypteddata);
                setBilldata(normalizeKeys(decrypteddata));

                decrypteddata.forEach(bill => {
                    delete bill._ID;
                    delete bill.LEDID;
                    delete bill.BLDGID;
                    delete bill.VOUCHERS;
                    delete bill.CREATEDAT;
                    delete bill.UPDATEDAT;
                    delete bill.__V;

                });

                createColumns(decrypteddata[0]);
                setDisplaybilldata(decrypteddata);

                setBillCalculationDone(true);
            }


        }



    }



    const handleRowSelected = state => {
        const selectedRows = state.selectedRows;
        setSelectedRows(selectedRows);



    };


    const Updateclickedfunc = (row) => {
        setSelectedRow(row);
        setFormData(row);
        console.log(row);


        setUpdateclicked(true);
        setSlideropen(true);

    };
    const handleFormUpdate = async (e) => {
        e.preventDefault();

        try {
            let temp = displaybilldata.map(item => {
                if (item.BILLGUID === formData.BILLGUID) {
                    return formData;
                }
                return item;
            });


            setDisplaybilldata(temp);
            setBilldata(normalizeKeys(temp));


        } catch (error) {
            console.log(error);
        } finally {
            setSelectedRow({});
            setFormData({});
        }
    };

    // Your DataTable component
    <DataTable
        columns={columns}
        data={displaybilldata}
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 15, 20]}
        selectableRows
        onSelectedRowsChange={handleRowSelected}
    />



    const handleSubmit = async () => {
        if(billcalculationdone){
            const response = await handleAdd(billdata, 'addbills', selectedbuildingState.apprevname);
            if (response) {
                alert("Bills Generation Succesfull");
                setBillCalculationDone(false);
            }
        }
        else{
            toast.error("Generate Bill First")
        }
   
    };


    const handleuploadclicked = async () => {
        const uploadresponse = await SaveUploadedExcel(excelFile, "saveuploadedexcel");
        if (uploadresponse[0].status) {
            toast.success(uploadresponse[0].message);

        }

    }


    // function getBase64Image(imgPath) {
    //     const img = new Image();
    //     img.src = imgPath;
    //     const canvas = document.createElement("canvas");
    //     canvas.width = img.width;
    //     canvas.height = img.height;
    //     const ctx = canvas.getContext("2d");
    //     ctx.drawImage(img, 0, 0);
    //     const dataURL = canvas.toDataURL("image/jpeg");
    //     return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    // }



    return (
        <StyledBillUpload slideropen={slideropen}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="container">
                <div className="sub-container">
                    <section className='upload-section'>
                        <span className='heading'>Upload File</span><br />
                        <label htmlFor="Society">Select Society:</label>
                        <select >
                            <option value="">Select Society</option>
                            {buildinglistState.length > 0 &&
                                buildinglistState.map((item) => (
                                    <option key={item.bldgid} value={item.bldgid}>
                                        {item.bldgname}
                                    </option>
                                ))
                            }
                        </select><br />
                        <input
                            style={{ display: 'block', textAlign: 'center', border: '1px solid #8E3B3E', margin: '10px 0' }}
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={(e) => {
                                setExcelFile(e.target.files[0]);
                                handleFileUpload(e, setExceldata);
                            }}

                        />
                        <button onClick={handleuploadclicked}>Upload</button>
                        {/* <button onClick={generatePDF}>Generate PDF</button>
                <button onClick={generateIndividualPDFs}>Generate Individual PDFs</button> */}
                    </section>


                    <section className='bill-generation'>
                        <span className='heading'>Bill Generation</span><br />

                        <div className="generationinputs">
                            <div>

                                <label htmlFor="">Select Month</label>
                                <input type="month" name="" id="" onChange={(e) => setBillmonth(e.target.value)} />

                            </div>

                            <div>
                                <label htmlFor="">Select Due date</label>
                                <input type="date" name="" id="" onChange={(e) => setDuedate(e.target.value)} />
                            </div>

                            <div>

                                <label htmlFor="">Select
                                    Bill date</label>
                                <input type="date" name="" id="" onChange={(e) => setBilldate(e.target.value)} />

                            </div>




                        </div>


                        <div className='generationbtns'>
                            <button  onClick={calculateBillHeads}>Generate Bill</button>

                            <button disabled = {!billcalculationdone} onClick={handleSubmit}>Send Bill</button>
                        </div>



                    </section>
                    <section className='show-billdata'>
                        <span className='heading'>Send Bills</span>
                        <div className="work-buttons">

                            <FontAwesomeIcon id='download-btn' icon={faFileDownload} onClick={() => handleExportExcel(selectedRows)} />


                            <FontAwesomeIcon id='upload-excel-btn' icon={faFileUpload} onClick={() => { setAddexcelbox(true); }} />



                            <input type="text" placeholder='search' id='search' onChange={(event) => { handlefilter(event, originalRecords, setDisplaybilldata) }} />


                            {addexcelfilebox && (
                                <div id="overlayaddexcelbox">
                                    <FontAwesomeIcon
                                        icon={faClose}
                                        id="emailmessageicon"
                                        onClick={() => { setAddexcelbox(false); }}
                                    />

                                    <span>Upload Excel</span>
                                    <input style={{ display: 'block', textAlign: 'center', border: '1px solid #8E3B3E' }} type="file" name="" id="" onChange={(e) => { handleFileUpload(e, setExceldata) }} />
                                    <button id='excelbox-submit-btn' onClick={() => { Processexceldata(); setAddexcelbox(false) }}>import</button>


                                </div>

                            )}

                        </div>

                        <DataTable
                            columns={columns}
                            data={displaybilldata}
                            pagination
                            paginationPerPage={5}
                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                            selectableRows
                            onSelectedRowsChange={handleRowSelected}




                        >

                        </DataTable>

                    </section>
                    <div className="slider">
                        <div className="icon">
                            <FontAwesomeIcon icon={faClose} id='closeicon' onClick={() => setSlideropen(false)} />
                            <p style={{ marginLeft: '2%' }}>Update</p>
                        </div>
                        <form className="slider-form" >
                            {/* {Object.keys(selectedRow).map((key) => {
                        return billheadlist.map((element) => {
                            if ((key).toLowerCase().replace(/\s/g, "").replace(/[^a-z0-9]/g, "") === (element.itemdisplayname).toLowerCase().replace(/\s/g, "").replace(/[^a-z0-9]/g, "")) {
                                return (
                                    <div key={key}>
                                        <label htmlFor={key}>{key}</label>
                                        <span style={{ fontSize: '0.8rem', color: 'green' }}>Current Value - {selectedRow[key]}</span>
                                        <input
                                            type="text"
                                            id={key}
                                            onChange={(e) => setFormData({ ...formData, [`head${element.headno}`]: e.target.value })}
                                        />
                                    </div>
                                );
                            }
                            return null;
                        });
                    })} */}
                            {Object.keys(formData).map((key) => {
                                let Smallcasekeys = key.toLowerCase();
                                if (excludeKeys.has(Smallcasekeys)) {
                                    // Directly assign the value to the key
                                    return (
                                        <input
                                            type="hidden"
                                            key={key}
                                            id={key}
                                            value={formData[key]}
                                            onChange={(e) => setFormData({ ...formData, [key]: Number(e.target.value) })}
                                        />
                                    );
                                }
                                if (Smallcasekeys == "adjustments") {
                                    return (
                                        <div key={key}>
                                            <label htmlFor={key}>{key}</label>
                                            <input
                                                type="text"
                                                id={key}
                                                value={formData[key]}
                                                onChange={(e) => setFormData({ ...formData, [key]: (e.target.value) })}
                                            />
                                        </div>
                                    )
                                }
                                return (
                                    <div key={key}>
                                        <label htmlFor={key}>{key}</label>
                                        <input
                                            type="text"
                                            id={key}
                                            value={formData[key]}
                                            onChange={(e) => setFormData({ ...formData, [key]: Number(e.target.value) })}
                                        />
                                    </div>
                                );
                            })}






                            <button type="submit" onClick={handleFormUpdate}>Update</button>
                        </form>
                    </div>

                </div>
            </div>
            <Header />
            <Sidebar />
        </StyledBillUpload>

    );
};

const StyledBillUpload = styled.div`
 


    .container{
        position: relative;
        left: 15%;
        width: 85%;
        min-height: 100vh;
        padding: 8vh 5%;
    }

    .sub-container{
    width: 100%;
    background-color: white;
    padding: 4vh 3%;
    box-shadow: 0px 4px 8px 0px #E2CACB; 


    }


    .upload-section{
        border: 1px solid #FDD5D6;
        border-radius: 5px;
        padding: 1%;
        display: none;
    }

    .bill-generation{
        border: 1px solid #FDD5D6;
        border-radius: 5px;
        padding: 1%;
    }

    .generationinputs{
        display: flex;
        gap: 5%;
    }

    .generationbtns{
        display: flex;
        gap: 1%;
    }

    .generationbtns > button{
        background-color: #8E3B3E;
        color: #fff;
        border: none;
        padding: 0.5%;
        border-radius: 10px;
    }
    

    .show-billdata{
        margin-top: 10vh;
        border: 1px solid #FDD5D6;
        border-radius: 5px;
        padding: 1%;
    }
    .heading {
        font-size: 1.5em;
        margin-bottom: 0.5vh;
        display: block;
    }
    label, select, input {
        display: block;
        margin-bottom: 10px;
    }
 
    .work-buttons{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        margin-right: 1vw;

    }

    #search{
        padding: 2px 10px;
        height: 4vh;
        border-radius: 10px;
        border: 2px solid #8E3B3E;

    }

    #search:focus{
        border: 2px solid #8E3B3E;
        outline: none;

    }

    #download-btn{
        margin-right: 1vw;
        border-radius: 10px;
        color: #8E3B3E;
        border: none;
        font-size: 1.8rem;
    }

    #upload-excel-btn{
    margin-right: 1vw;
    border-radius: 10px;
    color: #8E3B3E;
    border: none;
    font-size: 1.8rem;

    }

    #overlayaddexcelbox{
    position: absolute;
    top: 0vh;
    left: 40%;
    z-index: 2;
    width: 25%;
    height: 15vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 2rem;
    display: grid;
    place-items: center;
    
}

#excelbox-submit-btn{
    width: 40%;
    height: 3vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
    border-radius: 10px;
}

#emailmessageicon{
    position: absolute;
    top: 1vh;
    left: 2%;
    font-size: 1.8rem;
    color: #8E3B3E;

}

.slider{
    width: 20%;
    height: 100vh;
    position: fixed;
    right: 0;
    display: ${props => (props.slideropen ? 'block' : 'none')};
    transition: transform 0.3s ease-in-out;
    top: 0;
    background-color: #FDD5D6;
    transition: right 0.3s ease;
    z-index: 1;
    overflow-y: scroll;
    padding: 0px 1%;
}

.icon{
    display: flex;
    align-items: center;
    position: relative;
    top: 2vh;
    left: 5%;
    font-size: 1.2rem;

}

#closeicon{
        
        font-size: 2rem;
        color: #8E3B3E;

    }

    
.slider-form {
    max-width: 400px;
    margin: 5vh auto 0 auto;
}

.slider-form label {
    display: block;
    margin-bottom: 5px;
}

.slider-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.slider-form button {
    width: 100%;
    padding: 10px;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}


`;



export default Bill_generation;

