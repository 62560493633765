import axios from "axios";
import { decryptData } from "../Functions/Data_protection";
import { secretKey , apiUrl} from "../constant";


export const Adminload = async (uri , reference ) => {

    const response = await axios.get(`${apiUrl}${uri}`, {
        params: { reference },
      });
    const decryptedData = decryptData(response.data.payload, secretKey);
    console.log(decryptedData)
    return decryptedData


    

}
