import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMoneyBill, faHome, faExclamationTriangle, faTachometer, faChartBar, faCog, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import Drop_nav from './Drop_nav';

const NavPanel = () => {
  // Creating a state to toggle the Drop Nav
  const [opendropnav, setOpenDropNav] = useState(false);

  // This state contains the role of admin
  const adminState = useSelector(state => state.admin.admin);



  return (
    <StyledNav>
      <ul className='nav-list'>
        <NavLink to="/Dashboard">
          <li className='nav-link'>
            <FontAwesomeIcon icon={faTachometer} className='awesomeicons' />
            <span>Dashboard</span>
          </li>
        </NavLink>
        {adminState && adminState == 1 && (
          <NavLink to='/Housingfed'>
            <li className='nav-link'>
              <FontAwesomeIcon icon={faHome} className='awesomeicons' />
              <span>Housing Fed</span>
            </li>
          </NavLink>
        )}

        <NavLink to='/Buildings'>
          <li className='nav-link'>
            <FontAwesomeIcon icon={faHome} className='awesomeicons' />
            <span>Buildings</span>
          </li>
        </NavLink>
        <NavLink to='/Ledger'>
          <li className='nav-link'>
            <FontAwesomeIcon icon={faExclamationTriangle} className='awesomeicons' />
            <span>Ledgers</span>
          </li>
        </NavLink>

        <NavLink to='/Billheads'>
          <li className='nav-link'>
            <FontAwesomeIcon icon={faChartBar} className='awesomeicons' />
            <span>Bill Heads</span>
          </li>
        </NavLink>
        <NavLink to='/Billgeneration'>
          <li className='nav-link'>
            <FontAwesomeIcon icon={faChartBar} className='awesomeicons' />
            <span>Bill Generation</span>
          </li>
        </NavLink>
        <NavLink to='/Voucher'>
          <li className='nav-link'>
            <FontAwesomeIcon icon={faMoneyBill} className='awesomeicons' />
            <span>Vouchers</span>
          </li>
        </NavLink>
        <li className='nav-link'>
          <FontAwesomeIcon icon={faCog} className='awesomeicons' />
          <span>Settings</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className='awesomeicons'
            style={{ marginLeft: '90px', cursor: 'pointer' }} // Added cursor pointer
            onClick={() => setOpenDropNav(!opendropnav)}
          />
        </li>
      </ul>
      {opendropnav && <Drop_nav />}
    </StyledNav>
  );
};

const StyledNav = styled.div`
  margin-top: 20px;

  .nav-list {
    margin-top: 20px;
  }

  .nav-link {
    list-style: none;
    margin-top: 15px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  a {
    color: black;
    text-decoration: none;
  }

  .nav-link:hover {
    background-color: #ffa2a6;
  }

  .nav-link.active {
    background-color: #8e3b3e;
    color: white;
  }

  .nav-link.active a {
    color: white;
  }

  .nav-link > span {
    margin-left: 15px;
  }

  @media screen and (min-width: 720px) and (max-width: 980px) {
    .nav-link {
      font-size: 0.8rem;
      margin-top: 8px;
    }
  }
`;

export default NavPanel;
