import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    buildinglist: [],
    selectedbuildinglist: [],
    selectedbuilding : {},

};

const BuildingSlice = createSlice({
    name: 'building',
    initialState,
    reducers: {
        setBuildinglist: (state, action) => {
            state.buildinglist = action.payload;
        },
        setSelectedbuildinglist: (state, action) => {
            state.selectedbuildinglist = action.payload;
        },
        addBuilding: (state, action) => {
            state.buildinglist.push(action.payload);
        },
        removeBuilding: (state, action) => {
            return {
                ...state,
                buildinglist: state.buildinglist.filter(building => building.bldgid !== action.payload.bldgid)
            };
        },

        addSelectedBuilding: (state, action) => {
            state.selectedbuildinglist.push(action.payload);
        },
        removeSelectedBuilding: (state, action) => {
            state.selectedbuildinglist = state.selectedbuildinglist.filter(building => building.bldgid !== action.payload.bldgid);
        },
        updateBuilding: (state, action) => {
            const index = state.buildinglist.findIndex(building => building.bldgid === action.payload.bldgid);
            if (index !== -1) {
                state.buildinglist[index] = action.payload;
            }
        },
        updateSelectedBuilding: (state, action) => {
            const index = state.selectedbuildinglist.findIndex(building => building.bldgid === action.payload.bldgid);
            if (index !== -1) {
                state.selectedbuildinglist[index] = action.payload;
            }
        },

        setSelectedbuilding: (state, action) => {
            state.selectedbuilding = action.payload;
        },
    },
});

export const {
    setBuildinglist,
    setSelectedbuildinglist,
    addBuilding,
    removeBuilding,
    addSelectedBuilding,
    removeSelectedBuilding,
    updateBuilding,
    updateSelectedBuilding,
    setSelectedbuilding,
} = BuildingSlice.actions;

export default BuildingSlice.reducer;
